import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../../components/home';
import Hubs from '../../components/hubs';
import Favs from '../../components/favs';
import Support from '../../components/support';
import Search from '../../components/search';
import AboutUs from '../../components/aboutus';
import Terms from '../../components/terms';
import Install from '../../components/install';

const createRoutes = () => (
    <Switch>
        <Route path="/home" component={Home}  />
        <Route path="/hubs" component={Hubs}  />
        <Route path="/favs" component={Favs}  />
        <Route path="/support" component={Support}  />
        <Route path="/search" component={Search}  />
        <Route path="/aboutus" component={AboutUs}  />
        <Route path="/terms" component={Terms} />
        <Route path="/install" component={Install} />

        <Route path="/" component={Home}  />

    </Switch>
);

export default createRoutes;
