import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import AppLayout from '../hoc/AppLayout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Item from './Items/item'
import { Col, Row } from 'antd';
import backgroundImageBrowser from '../images/background.png';
import Label from '../elements/Label';
import UpIcon from '@material-ui/icons/ArrowUpward';
import * as actions from '../actions/menuSelection';

class Favs extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        sectionName: '',
        windowHeight: '100vh',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {
        const { sectionName } = this.props;

        let newSectionName = sectionName;
        if (newSectionName === undefined)
            newSectionName = '';

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };


        let isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        if (isInStandaloneMode() && isMobileOnly) {
            this.setState({ windowHeight: '100vh', });
        }
        else {
            this.setState({ windowHeight: window.innerHeight, });
        }
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }



    componentDidUpdate(prevProps, prevState) {
        const { sectionName } = this.props;

        if (sectionName !== prevProps.sectionName)
            this.setState({ sectionName: sectionName, });

    };

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };

    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };

    render() {
        const { favList, t } = this.props;
        const { pageWidth } = this.state;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });

        };


        const tabName = 'favs';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';

        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;

        else {
            if (isMobileOnly)
                sectionName = "#top";
            else
                sectionName = "#top*";
        }


        if (this.props.mainMenuKey !== tabName || (this.props.mainMenuKey === tabName && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }

        let favs = [];

        if (favList !== null && favList.length > 0)
            favs = JSON.parse(favList);

        let page = null;
        page = <React.Fragment>
            {isMobileOnly || pageWidth < 768 ?
                <MobileLayout pageName='home' pageTitle='Home'>
                    {/* <div style={{ backgroundColor: 'rgba(237,246,249, 1)', height: '100vh', marginTop: '0px' }}  > */}
                    <div style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', height: '100vh' }}>
                        {favs !== undefined && favs.length > 0 ?

                            <div className='fav-content'  >
                                {favs.map(value =>
                                    (
                                        <Item itemName={value} tabName={tabName} showFav={true} key={value} />
                                    ))}
                            </div>
                            :
                            <div className='fav-content-empty'  >
                                <div style={{ position: 'fixed', top: '200px', width: '100%', textAlign: 'center' }} >
                                    <Item itemName='favs.nofavs.title' tabName={tabName} showFav={false} key='favs.nofavs' />
                                </div>
                                <div style={{ position: 'fixed', bottom: '100px', right: '40px' }} >
                                    <Item itemName='flower1' tabName={tabName} showFav={false} key='favs.nofavspic' />
                                </div>
                            </div>

                        }

                    </div>
                </MobileLayout>
                :
                <AppLayout >


                    {favs !== undefined && favs.length > 0 ?
                        <div className='pageBackground' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '40px', height: '100vh' }}   >
                            <div style={{ position: 'relative' }}>
                                <div id="top" style={{ position: 'absolute', top: pageWidth <= 1024 ? '-188px' : '-188px' }} />
                            </div>
                            <div style={{ margin: pageWidth > 1600 ? '0px 180px 0px 180px' : '0px' }}>

                                <Col span={20} offset={2} >
                                    {favs.map(value =>
                                        (

                                            <div key={'div' + value} style={{ zIndex: 100000, width: pageWidth <= 1200 ? '45%' : '30%', display: 'inline-block', margin: '16px 16px 40px 16px', padding: '0px', verticalAlign: 'top', backgroundColor: 'white', border: '1px dotted lightgrey' }}>
                                                <Item itemName={value} tabName={tabName} showFav={true} key={value} />
                                            </div>

                                        ))}
                                </Col>
                                <Row style={{ paddingTop: '16px' }}>
                                    <Col span={20} offset={2} >
                                        <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                        <br />
                                        <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        :
                        <div className='fav-content-empty' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', height: '100vh' }} >
                            <div style={{ position: 'fixed', top: pageWidth <= 1024 ? '200px' : '400px', width: '100%', textAlign: 'center' }} >
                                <Item itemName='favs.nofavs.title' tabName={tabName} showFav={false} key='favs.nofavs' />
                            </div>
                            <div style={{ position: 'fixed', bottom: '100px', right: pageWidth <= 1024 ? '40px' : '120px' }} >
                                <Item itemName='flower1' tabName={tabName} showFav={false} key='favs.nofavspic' />
                            </div>
                        </div>

                    }


                </AppLayout>
            }
        </React.Fragment>;

        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        favList: state.menuSelection.favList,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Favs));
