import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../helpers/utility';

const initFavList = localStorage.getItem('favList') ;
const initSearchString = localStorage.getItem('search');
// const stored_MainMenuKey = localStorage.getItem('mainMenuKey');
// const stored_MainMenuName = localStorage.getItem('mainMenuName');
// const stored_MobileMenuIndex = localStorage.getItem('mobileMenuIndex');
const stored_MainMenuKey = null;
const stored_MainMenuName = null;
const stored_MobileMenuIndex = null;

const initialState = {
    mainMenuSelection: (stored_MainMenuKey ? [stored_MainMenuKey] : ['home']),
    mainMenuKey: (stored_MainMenuKey ? stored_MainMenuKey : "home"),
    mainMenuName: (stored_MainMenuName !== '' ? stored_MainMenuName : "Home"),
    mobileMenuIndex: (stored_MobileMenuIndex ? stored_MobileMenuIndex : "0"),

    pageSection:'',
    favList: initFavList?initFavList:[],
    searchString:initSearchString?initSearchString:'',
};
const setMainMenu = (state, action) => {
    return updateObject(state, { mainMenuSelection: [action.mainMenuSelection] , mainMenuKey:action.mainMenuKey, mainMenuName:action.mainMenuName, sectionName:action.sectionName});
};

const setMobileMenu = (state, action) => {
    return updateObject(state, { mainMenuKey: action.mainMenuKey, mainMenuName: action.mainMenuName, sectionName: action.sectionName});
};

const setMobileToolbar = (state, action) => {
    return updateObject(state, { mobileMenuIndex: action.mobileMenuIndex, mainMenuKey: action.mainMenuKey, mainMenuName: action.mainMenuName, sectionName: action.sectionName});
};

const setFavourite = (state, action) => {
    return updateObject(state, { favList: action.favList });
};


const setSearch = (state, action) => {
    return updateObject(state, { search: action.searchString });
};

const menuSelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MAIN_MENU: return setMainMenu(state, action);
        case actionTypes.MOBILE_MENU: return setMobileMenu(state, action);
        case actionTypes.MOBILE_TOOLBAR: return setMobileToolbar(state, action);
        case actionTypes.SET_FAV: return setFavourite(state, action);
        case actionTypes.SET_SEARCH: return setSearch(state,action);
        default: return state;
    }
};

export default menuSelectionReducer;