import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AppLayout from '../hoc/AppLayout/AppLayout'
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as actions from '../actions/menuSelection';
import Item from './Items/item'
import backgroundImageBrowser from '../images/background.png';
import { Row, Col } from 'antd';
import Label from '../elements/Label';
import UpIcon from '@material-ui/icons/ArrowUpward';


class Terms extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        sectionName: '',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {
        const { sectionName } = this.props;
        let lang = localStorage.getItem('i18nextLng');
        lang = lang.substr(0, 2);
        if (lang) { this.setLanguage(lang); }
        else { this.setLanguage('en'); }

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };

        this.setState({ sectionName: sectionName, });

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionName } = this.props;
        if (sectionName !== prevProps.sectionName)
            this.setState({ sectionName: sectionName, });
    }

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };

    setLanguage = (language) => {
        const { i18n } = this.props;
        i18n.changeLanguage(language);
    };

    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };


    render() {

        const { onReturnClick, t } = this.props;
        const { pageWidth } = this.state;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });

        };


        const tabName = '';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';
        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else
            sectionName = "#top";


        if (this.props.mainMenuKey !== "terms" || (this.props.mainMenuKey === "terms" && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }

        let page = null;

        if (pageWidth > 768)
            page =
                <AppLayout pageName='terms' pageTitle='Terms'>
                    {pageWidth <= 1024 ?

                        <div className='pageBackground web-content' style={{ backgroundImage: `url(${backgroundImageBrowser})` }} >
                            <div style={{ position: 'relative' }}>
                                <div id="top*" style={{ position: 'absolute', top: -200 }} />
                            </div>
                            <Row>
                                <Col span={22} offset={1}>
                                    <Item itemName='terms.title' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={10} offset={1}>
                                    <Item itemName='terms.part1' tabName={tabName} showFav={false} />


                                </Col>
                                <Col span={10} offset={1}>
                                    <Item itemName='terms.part2' tabName={tabName} showFav={false} />


                                </Col>
                            </Row>
                            <Row style={{ marginTop: '40px', paddingTop: '40px' }}>
                                <Col span={22} offset={1} >
                                    <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />
                                    <br />
                                    <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Col>
                            </Row>

                        </div>
                        :
                        <div className='pageBackground web-content' style={{ backgroundImage: `url(${backgroundImageBrowser})`, paddingTop: '40px' }} >
                            <div style={{ position: 'relative' }}>
                                <div id="top" style={{ position: 'absolute', top: -200 }} />
                            </div>

                            <Row>
                                <Col span={20} offset={2}>
                                    <Item itemName='terms.title' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={8} offset={2}>
                                    <Item itemName='terms.part1' tabName={tabName} showFav={false} />


                                </Col>
                                <Col span={8} offset={4}>
                                    <Item itemName='terms.part2' tabName={tabName} showFav={false} />


                                </Col>
                            </Row>
                            <Row style={{ marginTop: '40px', paddingTop: '40px' }}>
                                <Col span={20} offset={2} >
                                    <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />
                                    <br />
                                    <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Col>
                            </Row>
                        </div>
                    }
                </AppLayout>;

        else
            page = <MobileLayout pageName='terms' pageTitle={t('terms')} returnHome={() => onReturnClick('[home]', 'home')} >

                <div style={{ padding: '16px' }}>

                    <Item itemName='terms' tabName={tabName} showFav={false} />


                </div>
            </MobileLayout>;


        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Terms));
