import * as actionTypes from './actionTypes';

export const setMainMenu = (menuSelection, menuKey, sectionName) => {
    let menuIndex =0;

    let menuName = "";
    switch (menuKey) {
        case "home":
            menuName = "Home";
            break;
     case "hubs":
            menuName = "Hubs";
            menuIndex = 1;
            break;
     case "support":
            menuName = "Support";
            menuIndex = 2;
           break;
        case "favs":
            menuName = "Favs";
            menuIndex = 3;
            break;
        case "search":
            menuName = "Search";
            menuIndex = 4;
            break;
        case "aldergrove":
            menuName = "Aldergrove Hub";
            break;
        case "willoughby":
            menuName = "Willoughby Hub";
            break;
        case "lcdouglas":
            menuName = "Langley City Douglas Hub";
            break;
       case "lccommunity":
            menuName = "Langley City Community Hub";
            break;
        case "navigator":
            menuName = "Family Navigator";
            break;
      
            
        default:
    }
   
    setMobileToolbar(menuIndex);
    setMobileMenu(menuKey);

    localStorage.setItem('mainMenuKey', menuKey);
    localStorage.setItem('mainMenuName', menuName);
    localStorage.setItem('sectionName', sectionName);



    return {
        type: actionTypes.MAIN_MENU,
        mainMenuSelection: [menuSelection],
        mainMenuKey:menuKey,
        mainMenuName: menuName,
        sectionName: sectionName
    };
};

export const setMobileMenu = (menuKey) => {
    
  
    let menuName = "";
    switch (menuKey) {
        case "home":
            menuName = "Home"
            break;
        case "hubs":
            menuName = "Hubs"
            break;

        case "support":  
            menuName = "Support"
            break;
        case "favs":
            menuName = "Favs"
            break;
        case "search":
            menuName = "Search"
            break;


         default:
    }
    localStorage.setItem('mainMenuKey', menuKey);
    localStorage.setItem('mainMenuName', menuName);
    localStorage.setItem('sectionName', '');


    return {
        type: actionTypes.MOBILE_MENU,
        mainMenuKey: menuKey,
        mainMenuName:menuName,
        sectionName: ''
    };
};

export const setMobileToolbar = (menuIndex) => {

    let menuKey = "";
    let menuName ="";
    switch(menuIndex)
    {
        case 0:
            menuKey = "home";
            menuName ="Home"
            break;
        case 1:
            menuKey = "hubs";
            menuName = "Hubs"
            break;

        case 2:
            menuKey = "support";
            menuName = "Support"
            break;

        case 3:
            menuKey = "favs";
            menuName = "Favs"
            break;

        case 4:
            menuKey = "search";
            menuName = "Search"
            break;
            default:
    }

    localStorage.setItem('mainMenuKey', menuKey);
    localStorage.setItem('mainMenuName', menuName);
    localStorage.setItem('mobileMenuIndex', menuIndex);
    localStorage.setItem('sectionName', '');

    return {
        type: actionTypes.MOBILE_TOOLBAR,
        mobileMenuIndex: menuIndex,
        mainMenuKey: menuKey,
        mainMenuName: menuName,
        sectionName:''
    };
};

export const setFavourite = (itemName, status) =>{

    let favList = localStorage.getItem('favList');
    let newFavList = [];

    if(status === true)
    {
        if (favList === "[]" || favList ===null)
            newFavList.push(itemName);
        else{

            let index = newFavList.indexOf(itemName);
            if (index >-1) {
                newFavList = JSON.parse(favList);
                
                }
            else
            {
                newFavList = JSON.parse(favList);
                newFavList.push(itemName);
            }
        }
        favList = JSON.stringify(newFavList);

    }
    else{
        
        newFavList = JSON.parse(favList);
       

        let index = newFavList.indexOf(itemName);
        if (index > -1) {
            newFavList.splice(index, 1);
        }

        favList = JSON.stringify(newFavList);
       
    }

    localStorage.setItem('favList', favList);
    return {
        type: actionTypes.SET_FAV,
        favList: favList  
    };
};

export const setSearch = (searchString) => {
    localStorage.setItem('search', searchString);
    return {
        type: actionTypes.SET_SEARCH,
        searchString: searchString
    };
};