import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import AppLayout from '../hoc/AppLayout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchBar from './Navigation/SearchBar';
import Item from './Items/item'
import * as actions from '../actions/menuSelection';
import backgroundImageBrowser from '../images/background.png';
import { Col, Row } from 'antd';
import searchEn from '../locales/enSearch.json';
import searchEs from '../locales/esSearch.json';
import searchFr from '../locales/frSearch.json';
import searchKo from '../locales/koSearch.json';
import searchPj from '../locales/pjSearch.json';
import Label from '../elements/Label';
import UpIcon from '@material-ui/icons/ArrowUpward';

class Search extends Component {
    state = {
        searchList: [],
        searchString: '',
        windowHeight: '100vh',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {
        let searchString = localStorage.getItem('search');

        this.setState({ searchString: searchString, });

        if (searchString !== null) {
            this.search(searchString);

        }
        window.scrollTo(0, 0);


        let isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        if (isInStandaloneMode() && isMobileOnly) {
            this.setState({ windowHeight: '100vh', });
        }
        else {
            this.setState({ windowHeight: window.innerHeight, });
        }
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchString } = this.state;

        if (searchString !== prevState.searchString && searchString !== null) {
            this.search(searchString);
        }

    }
    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };
    search = (searchString) => {

        let searchList = [];
        let content = [];

        let language = localStorage.getItem('i18nextLng');

        switch (language) {
            case 'en':
                content = searchEn;

                break;
            case 'fr':
                content = searchFr;

                break;
            case 'es':
                content = searchEs;

                break;
            case 'ko':
                content = searchKo;

                break;
            case 'pj':
                content = searchPj;

                break;

            default:
                content = searchEn;
        }



        if (searchString !== '') {

            for (var key in content) {
                if (content.hasOwnProperty(key)) {
                    let currentValue = content[key].toUpperCase();
                    let searchWords = searchString.split(' ');

                    for (var searchWord in searchWords) {

                        if (currentValue.includes(searchWords[searchWord].toUpperCase().trim())) {

                            let results = key.split('.', 2);
                            let newKey = '';
                            if (results.length >= 2) {
                                newKey = results[0] + '.' + results[1];
                                let index = searchList.indexOf(newKey);
                                if (index === -1)
                                    searchList.push(newKey);
                            }

                        }
                    }

                }
            }
        }
        else
            searchList = [];

        this.setState({ searchList: searchList, searchString: searchString, });
        localStorage.setItem('search', searchString);


    };


    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };

    render() {

        const { searchList, searchString, pageWidth } = this.state;
        const { t } = this.props;

        let menuRedirect = null;
        let newMenu = "";
        const tabName = 'search';

        if (this.props.mainMenuKey !== "search") {
            newMenu = "/" + this.props.mainMenuKey + '#' + this.props.sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }



        let page = null;
        page = <React.Fragment>            {isMobileOnly || pageWidth < 768 ?
            <MobileLayout pageName='home' pageTitle='Home' >
                <div style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', height: '100%' }}>

                    <div className='search-menu-style'  >
                        <SearchBar onChange={this.search} searchString={searchString} placeholder={t('menu.search')} style={{ margin: '8px' }} />
                    </div>

                    {searchList.length > 0 ?

                        <div className='search-content' >
                            {searchList.map(value =>
                                (
                                    <Item itemName={value} tabName={tabName} showFav={true} key={value} />
                                ))}
                        </div>

                        :

                        <div className='search-content-empty' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', height: '100vh' }} >
                            <div style={{ position: 'fixed', top: '200px', width: '100%', textAlign: 'center' }} >
                                <Item itemName='search.noresults.title' tabName='searchTab' showFav={false} key='search.noresults' />
                            </div>
                            <div style={{ position: 'fixed', bottom: '100px', right: '40px' }} >
                                <Item itemName='bug' tabName='searchTab' description='image.bug' showFav={false} key='search.noresultspic' />
                            </div>
                        </div>
                    }
                </div>
            </MobileLayout>
            :
            <AppLayout >
                <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px' }}   >

                    <div style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', margin: '0px', padding: '0px', height: '118px', width: '100%' }}>
                        <Row style={{ paddingTop: '0px', position: 'fixed', top: pageWidth <= 1024 ? '164px' : '180px', left: '0px', width: '100%' }}>
                            <Col span={pageWidth <= 1024 ? 20 : 10} offset={2} style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat' }}  >
                                <SearchBar onChange={this.search} searchString={searchString} placeholder={t('menu.search')} style={{ margin: '8px' }} />
                            </Col>
                        </Row>
                    </div>
                    {searchList !== undefined && searchList.length > 0 ?
                        <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', position: 'fixed', top: '260px', left: '0px', height: '100%', paddingBottom: '280px', borderTop: 'thin solid, lightgrey' }}   >

                            <div style={{ margin: pageWidth > 1600 ? '0px 180px 0px 180px' : '0px' }}>


                                <Col span={20} offset={2} >
                                    {searchList.map(value =>
                                        (

                                            <div key={'div' + value} style={{ width: pageWidth <= 1200 ? '45%' : '30%', display: 'inline-block', margin: '16px 16px 40px 16px', padding: '0px', verticalAlign: 'top', backgroundColor: 'white', border: '1px dotted lightgrey' }}>
                                                <Item itemName={value} tabName='search' showFav={true} key={value} />
                                            </div>

                                        ))}
                                </Col>
                                <Row style={{ paddingTop: '8px' }}>
                                    <Col span={20} offset={2} >
                                        <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                        <br />
                                        <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        :
                        <div className='search-content-empty' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', paddingTop: '0px', height: '100vh' }} >

                            <div style={{ position: 'fixed', top: pageWidth <= 1024 ? '320px' : '400px', width: '100%', textAlign: 'center' }} >
                                <Item itemName='search.noresults.title' tabName='search' showFav={false} key='search.noresults' />
                            </div>
                            <div style={{ position: 'fixed', bottom: '100px', right: pageWidth <= 1024 ? '40px' : '120px' }} >
                                <Item itemName='bug' tabName='searchTab' description='image.bug' showFav={false} key='search.noresultspic' />
                            </div>
                        </div>

                    }
                </div>
            </AppLayout>
        }
        </React.Fragment>;

        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}

const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        searchString: state.menuSelection.searchString,
        sectionName: state.menuSelection.sectionName
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSearch: (searchString) => dispatch(actions.setSearch(searchString)),
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Search));
