import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import AppLayout from '../hoc/AppLayout/AppLayout'
import { isMobileOnly, isTablet } from "react-device-detect";
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as actions from '../actions/menuSelection';
import Item from './Items/item'
import { Col, Row } from 'antd';
import UpIcon from '@material-ui/icons/ArrowUpward';
import backgroundImage2 from '../images/background.png';
// import backgroundImage3 from '../images/background.png';
import backgroundImage4 from '../images/background.png';
import backgroundImage5 from '../images/background.png';
import backgroundImage from '../images/background.png';
import Label from '../elements/Label';

class Hubs extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        windowHeight: '100vh',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant", block: "start" });
        };


        let isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        if (isInStandaloneMode() && isMobileOnly) {
            this.setState({ windowHeight: '100vh', });
        }
        else {
            this.setState({ windowHeight: window.innerHeight, });
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };


    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };

    render() {

        const { t } = this.props;
        const { pageWidth } = this.state;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant", block: "start" });

        };


        const tabName = 'hubs';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';
        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else {
            if (isMobileOnly)
                sectionName = "#top";
            else
                sectionName = "#top*";

        }

        if (this.props.mainMenuKey !== "hubs") {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }


        let page = null;
        page = <React.Fragment>
            {isMobileOnly || pageWidth < 768 ?
                <MobileLayout pageName='home' pageTitle='Hubs'>
                    <div id="top" />
                    <div className='pageBackground ' style={{ margin: '16px 8px 0px 8px', paddingBottom: '0px' }} >
                        <Link to="hubs#aldergrove" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'aldergrove')}>
                            <div style={{ width: '50%', display: 'inline-block', paddingRight: '8px', verticalAlign: 'top' }}>  <Item itemName='aldergroveSmall' tabName={tabName} showFav={false} /></div>
                        </Link>

                        {/* <Link to="hubs#lcdouglas" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lcdouglas')}>
                            <div style={{ width: '50%', display: 'inline-block', verticalAlign: 'top' }}>  <Item itemName='lcdouglasSmall' tabName={tabName} showFav={false} /></div>
                        </Link> */}
                        <Link to="hubs#lccommunity" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lccommunity')}>

                            <div style={{ width: '50%', display: 'inline-block', paddingRight: '8px', verticalAlign: 'top' }}>   <Item itemName='lccommunitySmall' tabName={tabName} showFav={false} /></div>
                        </Link>
                        <Link to="hubs#willoughby" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'willoughby')}>


                            <div style={{ width: '50%', display: 'inline-block', verticalAlign: 'top' }}>    <Item itemName='willoughbySmall' tabName={tabName} showFav={false} /></div>
                        </Link>
                    </div>

                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage2})`, margin: '0px', paddingTop: '0px' }} >
                        <div style={{ position: 'relative' }}>
                            <div id="aldergrove" style={{ position: 'absolute', top: '60px' }} />
                        </div>
                        <Item itemName='aldergrove.heading' tabName={tabName} showFav={false} />
                        <div className='up-arrow-float-right'> <Link to="hubs#top" ><UpIcon className='upIcon' /> </Link></div>

                        <div className='sectionContent'>
                            <br></br>
                            <Col span={12}>
                                <Item itemName='aldergrove.address' tabName={tabName} showFav={false} />
                            </Col>
                            <Col span={12}>
                                <Item itemName='boyBalloon' tabName={tabName} showFav={false} description='image.boy.balloon' />
                            </Col>

                            <Item itemName='aldergroveTitle' tabName={tabName} showFav={false} />
                            <Item itemName='aldergrove.dropin' tabName={tabName} showFav={true} />
                            <Item itemName='aldergrove.bestbabies' tabName={tabName} showFav={true} />
                            {/* <Item itemName='aldergrove.ywca' tabName={tabName} showFav={true} /> */}
                            <Item itemName='aldergrove.immunization' tabName={tabName} showFav={true} />
                            <Item itemName='aldergrove.outreach' tabName={tabName} showFav={true} />
                            {/* <Item itemName='aldergrove.playgroup' tabName={tabName} showFav={true} /> */}
                            <br></br>

                        </div>
                    </div>
                    {/* <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage3})` }} >
                        <div style={{ position: 'relative' }}>
                            <div id="lcdouglas" style={{ position: 'absolute', top: '60px' }} />
                        </div>
                        <Item itemName='lcdouglas.heading' tabName={tabName} showFav={false} />
                        <div className='up-arrow-float-right'> <Link to="hubs#top" ><UpIcon className='upIcon' /> </Link></div>
                        <div className='sectionContent'>
                            <Row>
                                <Col span={12}>
                                    <Item itemName='lcdouglas.address' tabName={tabName} showFav={false} />
                                </Col>
                                <Col span={12} style={{ paddingTop: '40px' }}>
                                    <Item itemName='girl5' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>

                            <Item itemName='lcdouglasTitle' tabName={tabName} showFav={false} />

                            <Item itemName='lcdouglas.programs' tabName={tabName} showFav={true} />
                            <Item itemName='lcdouglas.bestbabies' tabName={tabName} showFav={true} />
                            <Item itemName='lcdouglas.strongstart' tabName={tabName} showFav={true} />
                         

                        </div>
                    </div> */}
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage4})` }} >


                        <div style={{ position: 'relative' }}>
                            <div id="lccommunity" style={{ position: 'absolute', top: '60px' }} />
                        </div>
                        <Item itemName='lccommunity.heading' tabName={tabName} showFav={false} />
                        <div className='up-arrow-float-right'> <Link to="hubs#top" ><UpIcon className='upIcon' /> </Link></div>

                        <div className='sectionContent'>
                            <Col span={12}>
                                <Item itemName='boy1' tabName={tabName} showFav={false} />
                            </Col>
                            <Col span={12} style={{ paddingTop: '40px' }}>
                                <Item itemName='lccommunity.address' tabName={tabName} showFav={false} />
                            </Col>

                            <Item itemName='lccommunityTitle' tabName={tabName} showFav={false} />
                            <Item itemName='lccommunity.familyplace' tabName={tabName} showFav={true} />
                            <Item itemName='lccommunity.mutlicultural' tabName={tabName} showFav={true} />
                            <Item itemName='lccommunity.parenting' tabName={tabName} showFav={true} />
                            <Item itemName='lccommunity.settlement' tabName={tabName} showFav={true} />
                            <Item itemName='lccommunity.workshops' tabName={tabName} showFav={true} />
                            <Item itemName='lccommunity.poverty' tabName={tabName} showFav={true} />
                            <br></br>

                        </div>
                    </div>
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage5})` }} >

                        <div style={{ position: 'relative' }}>
                            <div id="willoughby" style={{ position: 'absolute', top: '60px' }} />
                        </div>
                        <Item itemName='willoughby.heading' tabName={tabName} showFav={false} />
                        <div className='up-arrow-float-right'> <Link to="hubs#top" ><UpIcon className='upIcon' /> </Link></div>

                        <div className='sectionContent'>
                            <Row>
                                <Col span={10} style={{ paddingTop: '40px' }}>
                                    <Item itemName='girl6' tabName={tabName} showFav={false} />
                                </Col>
                                <Col span={14} >
                                    <Item itemName='willoughby.address' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Item itemName='willoughbyTitle' tabName={tabName} showFav={false} />
                                <Item itemName='willoughby.babytalk' tabName={tabName} showFav={true} />
                                <Item itemName='willoughby.creativeart' tabName={tabName} showFav={true} />
                                <Item itemName='willoughby.familyplace' tabName={tabName} showFav={true} />
                                <Item itemName='family.postpartum' tabName={tabName} showFav={true} />
                                <Item itemName='willoughby.spanish' tabName={tabName} showFav={true} />
                                <Item itemName='willoughby.ywca' tabName={tabName} showFav={true} />

                            </Row>
                            <Row style={{ paddingTop: '48px' }}>
                                <div className='up-arrow-float-right'> <Link to="hubs#top" ><UpIcon className='upIcon' /> </Link></div>
                            </Row>

                        </div>
                    </div>

                </MobileLayout>
                :
                <AppLayout pageName='home' pageTitle='Hubs'>
                    <div style={{ position: 'relative' }}>
                        <div id="top*" style={{ position: 'absolute', top: '-188px' }} />
                    </div>
                    {pageWidth <= 1024 ?
                        <React.Fragment>
                            <div className='pageBackground web-content ' style={{ padding: '0px 24px 0px 24px' }} >
                                <Link to="hubs#aldergrove" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'aldergrove')}>
                                    <div style={{ width: '50%', display: 'inline-block', paddingRight: '8px', verticalAlign: 'top' }}>  <Item itemName='aldergroveSmall' tabName={tabName} showFav={false} /></div>
                                </Link>

                                <Link to="hubs#lccommunity" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lccommunity')}>

                                    <div style={{ width: '50%', display: 'inline-block', paddingRight: '8px', verticalAlign: 'top' }}>   <Item itemName='lccommunitySmall' tabName={tabName} showFav={false} /></div>
                                </Link>
                                <Link to="hubs#willoughby" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'willoughby')}>

                                    <div style={{ width: '50%', display: 'inline-block', verticalAlign: 'top' }}>    <Item itemName='willoughbySmall' tabName={tabName} showFav={false} /></div>
                                </Link>
                            </div>

                            <div className='pageBackground web-content ' style={{ backgroundImage: `url(${backgroundImage})`, padding: '0px 24px 0px 24px' }} >

                                <Row style={{ marginBottom: '16px' }}>
                                    <Item itemName='aldergrove.heading.browser' tabName={tabName} showFav={false} />
                                </Row>
                                <Row style={{ marginBottom: '8px' }}>
                                    <Item itemName='aldergroveTitle' tabName={tabName} showFav={true} /><br />

                                </Row>

                                <Col span={12} offset={0} >
                                    <Item itemName='aldergrove.dropin' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='aldergrove.bestbabies' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='aldergrove.immunization' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='aldergrove.outreach' tabName={tabName} showFav={true} />

                                </Col>
                                <Col span={12} offset={0} >
                                    <Row>
                                        <Col span={10} offset={3}>
                                            <Item itemName='aldergrove.address' tabName={tabName} showFav={false} />
                                        </Col>
                                        <Col span={6} offset={5}>
                                            <Item itemName='flower1' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>
                                    <br></br>

                                    <Row>

                                        <Col span={20} offset={4}>
                                            <Item itemName='boyBalloon' tabName={tabName} showFav={false} description='image.boy.balloon' />

                                        </Col>
                                    </Row>


                                </Col>
                            </div>

                            <div className='pageBackground  web-content' style={{ backgroundImage: `url(${backgroundImage})`, padding: '24px 24px 0px 24px' }} >
                                <div style={{ position: 'relative' }}>
                                    <div id="lccommunity" style={{ position: 'absolute', top: isTablet ? '-188px' : '60px' }} />
                                </div>

                                <Row style={{ marginBottom: '16px' }}>
                                    <Item itemName='lccommunity.heading.browser' tabName={tabName} showFav={false} />
                                </Row>

                                <Row style={{ marginBottom: '8px' }}>
                                    <Item itemName='lccommunityTitle' tabName={tabName} showFav={true} /><br />
                                    <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>

                                </Row>

                                <Col span={12} offset={0} >
                                    <Item itemName='lccommunity.familyplace' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='lccommunity.multicultural' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='lccommunity.parenting' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='lccommunity.workshops' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='lccommunity.settlement' tabName={tabName} showFav={true} /><br />
                                </Col>

                                <Col span={12} offset={0} >

                                    <Row>
                                        <Col span={14} offset={5}>
                                            <Item itemName='lccommunity.address' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>
                                    <Item itemName='lccommunity.poverty' tabName={tabName} showFav={true} />

                                    <Row>
                                        <Col span={16} offset={8}>
                                            <Item itemName='boy1' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>

                                </Col>



                            </div>

                            <div className='pageBackground  web-content' style={{ backgroundImage: `url(${backgroundImage})`, padding: '24px 24px 0px 24px' }} >
                                <div style={{ position: 'relative' }}>
                                    <div id="willoughby" style={{ position: 'absolute', top: isTablet ? '-188px' : '60px' }} />
                                </div>
                                <Row style={{ marginBottom: '16px' }}>
                                    <Item itemName='willoughby.heading.browser' tabName={tabName} showFav={false} />
                                </Row>

                                <Row style={{ marginBottom: '8px' }}>
                                    <Item itemName='willoughbyTitle' tabName={tabName} showFav={true} /><br />
                                    <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Row>

                                <Col span={12} offset={0} >

                                    <Item itemName='willoughby.babytalk' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='willoughby.creativeart' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='willoughby.familyplace' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='family.postpartum' tabName={tabName} showFav={true} /><br />
                                </Col>
                                <Col span={12} offset={0} >
                                    <Row>
                                        <Col span={14} offset={5} style={{ marginTop: '16px' }}>
                                            <Item itemName='willoughby.address' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>
                                    <Item itemName='willoughby.spanish' tabName={tabName} showFav={true} /><br />
                                    <Item itemName='willoughby.ywca' tabName={tabName} showFav={true} />
                                    <Row style={{ marginTop: '4px' }}>
                                        <Col span={20} offset={2}>
                                            <Item itemName='girl6' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Row style={{ paddingTop: '16px' }}>
                                    <Col span={isTablet ? 22 : 20} offset={isTablet ? 1 : 2} >
                                        <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                        <br />
                                        <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                        <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>
                                    </Col>
                                </Row>
                            </div>



                        </React.Fragment>
                        :
                        <React.Fragment>

                            <div style={{ margin: pageWidth > 1600 ? '0px 180px 0px 180px' : '0px' }}>

                                <div className='pageBackground web-content ' style={{ backgroundImage: `url(${backgroundImage})`, padding: '0px 0px 40px 0px' }} >


                                    <div className='pageBackground web-content ' style={{ backgroundColor: 'white', margin: '0px 0px 24px 0px', padding: '36px 0px 24px 0px', borderBottom: pageWidth > 1600 ? 'none' : '1px solid darkgrey' }} >
                                        <Col span={3} style={{ textAlign: 'right', paddingRight: '16px' }} >
                                            <Item itemName='flower2' tabName={tabName} showFav={false} />

                                        </Col>
                                        <Col span={6} >
                                            <Link to="hubs#aldergrove" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'aldergrove')}>
                                                <div style={{ width: '100%', height: '100px', display: 'inline-block', paddingRight: '16px', verticalAlign: 'top' }}>  <Item itemName='aldergroveHub' tabName={tabName} showFav={false} /></div>
                                            </Link>
                                        </Col>
                                        {/* <Col span={4} offset={1}>

                                            <Link to="hubs#lcdouglas" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lcdouglas')}>
                                                <div style={{ width: '100%', minHeight: '100px', display: 'inline-block', verticalAlign: 'top', paddingRight: '16px' }}>  <Item itemName='lcdouglasHub' tabName={tabName} showFav={false} /></div>
                                            </Link>
                                        </Col> */}
                                        <Col span={6} offset={1}>

                                            <Link to="hubs#lccommunity" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lccommunity')}>

                                                <div style={{ width: '100%', height: '100px', display: 'inline-block', paddingRight: '16px', verticalAlign: 'top' }}>   <Item itemName='lccommunityHub' tabName={tabName} showFav={false} /></div>
                                            </Link>
                                        </Col>
                                        <Col span={6} offset={1}>

                                            <Link to="hubs#willoughby" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'willoughby')}>


                                                <div style={{ width: '100%', height: '100px', display: 'inline-block', verticalAlign: 'top' }}>    <Item itemName='willoughbyHub' tabName={tabName} showFav={false} /></div>
                                            </Link>
                                        </Col>
                                    </div>

                                    <Row style={{ paddingTop: '16px' }}>
                                        <Col span={22} offset={1} >
                                            <Item itemName='aldergrove.heading.browser' tabName={tabName} showFav={false} />

                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: '36px' }}>
                                        <Col span={20} offset={2}>
                                            <Item itemName='aldergroveTitle' tabName={tabName} showFav={true} /><br />
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col span={6} offset={2}>
                                            <Item itemName='aldergrove.dropin' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='aldergrove.bestbabies' tabName={tabName} showFav={true} /> <br />
                                            {/* <Item itemName='aldergrove.ywca' tabName={tabName} showFav={true} /> */}

                                        </Col>
                                        <Col span={6} offset={1}>
                                            <Item itemName='aldergrove.immunization' tabName={tabName} showFav={true} />
                                            <br /> <Item itemName='aldergrove.outreach' tabName={tabName} showFav={true} />
                                            {/* <br /><Item itemName='aldergrove.playgroup' tabName={tabName} showFav={true} /> */}

                                        </Col>
                                        <Col span={6} offset={1}>
                                            <Row>

                                                <Col span={10} >
                                                    <Item itemName='aldergrove.address' tabName={tabName} showFav={false} />
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col span={19} offset={5}>
                                                    <Item itemName='boyBalloon' tabName={tabName} showFav={false} description='image.boy.balloon' />
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                </div>

                                {/* <div className='pageBackground  web-content' style={{ backgroundImage: `url(${backgroundImage})`, padding: '16px 0px 16px 16px' }}  >

                                    <div style={{ position: 'relative' }}>
                                        <div id="lcdouglas" style={{ position: 'absolute', top: '-188px' }} />
                                    </div>

                                    <Row style={{ paddingTop: '16px' }}>
                                        <Col span={22} offset={1} >
                                            <Item itemName='lcdouglas.heading.browser' tabName={tabName} showFav={false} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '24px', marginBottom: '0px' }}>
                                        <Col span={21} offset={2}>
                                            <Item itemName='lcdouglasTitle' tabName={tabName} showFav={true} /><br />
                                            <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>
                                        </Col>
                                    </Row>


                                    <Row style={{ marginTop: '0px' }}>
                                        <Col span={6} offset={2}>
                                            <Item itemName='lcdouglas.programs' tabName={tabName} showFav={true} /> <br />
                                         </Col>
                                        <Col span={6} offset={1}>
                                            <Item itemName='lcdouglas.bestbabies' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='lcdouglas.strongstart' tabName={tabName} showFav={true} />

                                        </Col>

                                        <Col span={6} offset={1}>
                                            <Col span={16} offset={4}>
                                                <Item itemName='lcdouglas.address' tabName={tabName} showFav={false} />
                                            </Col>


                                            <Col span={24} style={{ paddingTop: '80px' }}>
                                                <Item itemName='girl5' tabName={tabName} showFav={false} style={{ width: '60%', textAlign: 'right' }} />
                                            </Col>

                                        </Col>
                                    </Row>


                                </div> */}

                                <div className='pageBackground  web-content' style={{ backgroundImage: `url(${backgroundImage})`, padding: '16px 0px 16px 16px' }} >

                                    <div style={{ position: 'relative' }}>
                                        <div id="lccommunity" style={{ position: 'absolute', top: '-188px' }} />

                                    </div>

                                    <Row style={{ paddingTop: '16px' }}>
                                        <Col span={22} offset={1} >
                                            <Item itemName='lccommunity.heading.browser' tabName={tabName} showFav={false} />

                                        </Col>
                                    </Row>


                                    <Row style={{ marginTop: '36px' }}>
                                        <Col span={21} offset={2}>
                                            <Item itemName='lccommunityTitle' tabName={tabName} showFav={true} /><br />
                                            <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>

                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col span={6} offset={2}>
                                            <Item itemName='lccommunity.familyplace' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='lccommunity.multicultural' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='lccommunity.parenting' tabName={tabName} showFav={true} />
                                        </Col>
                                        <Col span={6} offset={1}>
                                            <Item itemName='lccommunity.settlement' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='lccommunity.workshops' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='lccommunity.poverty' tabName={tabName} showFav={true} />
                                        </Col>

                                        <Col span={6} offset={1}>
                                            <Row>
                                                <Col span={16} offset={4}>
                                                    <Item itemName='lccommunity.address' tabName={tabName} showFav={false} />
                                                </Col>
                                            </Row>

                                            <Row style={{ paddingTop: '40px' }}>
                                                <Col span={24} offset={0}>
                                                    <Item itemName='boy1' tabName={tabName} showFav={false} />
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>


                                </div>

                                <div className='pageBackground  web-content' style={{ backgroundImage: `url(${backgroundImage})`, padding: '16px 0px 16px 16px' }} >
                                    <div style={{ position: 'relative' }}>
                                        <div id="willoughby" style={{ position: 'absolute', top: '-188px' }} />
                                    </div>



                                    <Row style={{ paddingTop: '16px', marginBottom: '16px' }}>
                                        <Col span={22} offset={1} >
                                            <Item itemName='willoughby.heading.browser' tabName={tabName} showFav={false} />

                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '36px' }}>
                                        <Col span={21} offset={2}>
                                            <Item itemName='willoughbyTitle' tabName={tabName} showFav={true} /><br />
                                            <div className='up-arrow-float-right'> <Link to="hubs#top*" ><UpIcon className='upIcon' /> </Link></div>
                                        </Col>
                                    </Row>


                                    <Row >
                                        <Col span={6} offset={2}>
                                            <Item itemName='willoughby.babytalk' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='willoughby.creativeart' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />
                                        </Col>
                                        <Col span={6} offset={1}>


                                            {/* <Item itemName='willoughby.infant' tabName={tabName} showFav={true} /> */}
                                            {/* <br /> */}
                                            {/* <Item itemName='willoughby.playgroup' tabName={tabName} showFav={true} />
                                            <br /> */}
                                            {/* <Item itemName='family.postpartum' tabName={tabName} showFav={true} /> */}
                                            <Item itemName='willoughby.familyplace' tabName={tabName} showFav={true} />
                                            <br />
                                            <Item itemName='willoughby.spanish' tabName={tabName} showFav={true} />
                                            <br />
                                            <Row style={{ paddingTop: '16px' }}>
                                                <Item itemName='girl6' tabName={tabName} showFav={false} />
                                            </Row>


                                        </Col>
                                        <Col span={6} offset={1}>
                                            <Row>
                                                <Col span={14} offset={5} style={{ marginTop: '0px' }}>
                                                    <Item itemName='willoughby.address' tabName={tabName} showFav={false} />
                                                </Col>
                                            </Row>
                                            <Item itemName='willoughby.ywca' tabName={tabName} showFav={true} />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingTop: '16px' }}>
                                        <Col span={21} offset={1} >
                                            <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                            <br />
                                            <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                            <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </React.Fragment>
                    }


                </AppLayout>
            }
        </React.Fragment>;

        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Hubs));
