import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import i18nReducer from './i18n';
import menuSelectionReducer from './menuSelections';

const createRootReducer = history =>
  combineReducers({
    i18n: i18nReducer,
    menuSelection: menuSelectionReducer,
    router: connectRouter(history)

   });

export default createRootReducer;
