import React, { Component } from 'react';

export default class Label extends Component {
  render() {
    const {
      caption,
      style,
      colon,
      labelType,
      ...rest
    } = this.props;


    let typeFontSize = '14px';
    let typeFontWeight = 500;
  
    switch (labelType) {
      case "H1":
        typeFontSize = '96px';
        typeFontWeight = 300;
        break;
      case "H2":
        typeFontSize = '60px';
        typeFontWeight = 300;
        break;
      case "H3":
        typeFontSize = '48px';
        break;
      case "H4":
        typeFontSize = '34px';
        break;
      case "H5":
        typeFontSize = '24px';
        break;
      case "H6":
        typeFontSize = '20px';
        typeFontWeight = 700;
        break;
      case "Subtitle1":
        typeFontSize = '16px';
        break;
      case "Subtitle2":
        typeFontWeight = 700;
        break;
      case "Body1":
        typeFontSize = '16px';
        break;

      default:

    }


    return (
      <div style={{ display: "inline-block", fontSize: typeFontSize, fontWeight: typeFontWeight, ...style }}  {...rest} >
        {caption}{colon ? ":" : ""}
      </div>
    )
  }
}
