import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AppLayout from '../hoc/AppLayout/AppLayout'
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as actions from '../actions/menuSelection';
import { isTablet } from "react-device-detect";
import Item from './Items/item'
import backgroundImageBrowser from '../images/background.png';
import { Row, Col } from 'antd';
import logo1 from '../images/inclusionLangleySociety.jpg';
import logo2 from '../images/EncompassSupportServices.png';
import logo3 from '../images/MinistryChildrenDevelopment.jpg';
import logo4 from '../images/LangleyCommunityServiceSociety.png';
// import logo5 from '../images/LangleyCity.png';
import Label from '../elements/Label';
import UpIcon from '@material-ui/icons/ArrowUpward';
import * as Contacts from '../assets/contacts.json';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';


class AboutUs extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        sectionName: '',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {
        const { sectionName } = this.props;
        let lang = localStorage.getItem('i18nextLng');
        lang = lang.substr(0, 2);
        if (lang) { this.setLanguage(lang); }
        else { this.setLanguage('en'); }

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };

        this.setState({ sectionName: sectionName, });
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionName } = this.props;
        if (sectionName !== prevProps.sectionName)
            this.setState({ sectionName: sectionName, });
    }

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };

    setLanguage = (language) => {
        const { i18n } = this.props;
        i18n.changeLanguage(language);
    };

    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };


    render() {

        const { onReturnClick, t } = this.props;
        const { pageWidth } = this.state;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });

        };


        const tabName = '';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';
        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else
            sectionName = "#top";


        if (this.props.mainMenuKey !== "aboutUs" || (this.props.mainMenuKey === "aboutUs" && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }

        let page = null;

        if (pageWidth > 768)
            page =
                <AppLayout pageName='aboutUs' pageTitle='About Us'>
                    {pageWidth <= 1024 ?

                        <div className='pageBackground web-content' style={{ backgroundImage: `url(${backgroundImageBrowser})` }} >
                            <div style={{ position: 'relative' }}>
                                <div id="top*" style={{ position: 'absolute', top: -200 }} />
                            </div>
                            <Row >
                                <Col span={22} offset={1}>
                                    <Item itemName='aboutUsTitle' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '24px' }}>
                                <Col span={10} offset={1}>
                                    <Item itemName='aboutUsDetail1' tabName={tabName} showFav={false} />
                                    <Item itemName='aboutUsDetail3' tabName={tabName} showFav={false} />
                                </Col>
                                <Col span={10} offset={2}>
                                    <Item itemName='aboutUsDetail2' tabName={tabName} showFav={false} />
                                    <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />
                                </Col>
                            </Row>

                            <Col span={24} >
                                <Row style={{ paddingTop: '40px', background: 'white' }} type="flex" justify="space-around" align="middle" >

                                    <Col span={2} >
                                        <img src={logo1} alt='logo' width='100%' />
                                    </Col>
                                    <Col span={3} offset={0}>
                                        <img src={logo2} alt='logo' width='100%' />
                                    </Col>
                                    <Col span={5} offset={0}>
                                        <img src={logo3} alt='logo' width='100%' />
                                    </Col>

                                    <Col span={3} offset={0}>
                                        <img src={logo4} alt='logo' width='100%' />
                                    </Col>
                                    {/* <Col span={3} offset={0}>
                                        <img src={logo5} alt='logo' width='100%' />
                                    </Col> */}

                                </Row>

                            </Col>

                            <Row style={{ paddingTop: '16px' }}>
                                <Col span={isTablet ? 22 : 20} offset={isTablet ? 1 : 2} >
                                    <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                    <br />
                                    <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className='pageBackground web-content' style={{ backgroundImage: `url(${backgroundImageBrowser})`, paddingTop: '40px' }} >
                            <div style={{ position: 'relative' }}>
                                <div id="top" style={{ position: 'absolute', top: -200 }} />
                            </div>
                            <div style={{ margin: pageWidth > 1600 ? '0px 180px 0px 180px' : '0px' }}>

                                <Row >
                                    <Col span={16} offset={2}>
                                        <Item itemName='aboutUsTitle' tabName={tabName} showFav={false} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '40px' }}>
                                    <Col span={6} offset={2}>
                                        <Item itemName='aboutUsDetail1' tabName={tabName} showFav={false} />
                                        <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />

                                    </Col>
                                    <Col span={6} offset={1}>
                                        <Item itemName='aboutUsDetail2' tabName={tabName} showFav={false} />
                                    </Col>
                                    <Col span={6} offset={1}>
                                        <Item itemName='aboutUsDetail3' tabName={tabName} showFav={false} />
                                        <Item itemName='rainbow' tabName={tabName} showFav={false} />

                                    </Col>
                                </Row>
                                <Col span={20} offset={2}>
                                    <Row style={{ paddingTop: '40px', background: 'white' }} type="flex" justify="space-around" align="middle" >

                                        <Col span={2} >
                                            <img src={logo1} alt='logo' width='100%' />
                                        </Col>
                                        <Col span={3} offset={0}>
                                            <img src={logo2} alt='logo' width='100%' />
                                        </Col>
                                        <Col span={5} offset={0}>
                                            <img src={logo3} alt='logo' width='100%' />
                                        </Col>

                                        <Col span={3} offset={0}>
                                            <img src={logo4} alt='logo' width='100%' />
                                        </Col>
                                        {/* <Col span={3} offset={0}>
                                            <img src={logo5} alt='logo' width='100%' />
                                        </Col> */}

                                    </Row>

                                </Col>

                                <Row style={{ marginTop: '40px', paddingTop: '40px' }}>
                                    <Col span={20} offset={2} >
                                        <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                        <br />
                                        <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </AppLayout>;

        else
            page = <MobileLayout pageName='aboutUs' pageTitle={t('menu.aboutus')} returnHome={() => onReturnClick('[home]', 'home')} >

                <div style={{ padding: '16px', background: 'white' }}>


                    <Item itemName='aboutUsDetail1' tabName={tabName} showFav={false} />
                    <div style={{ padding: '0px 24px 16px 24px' }}> <Item itemName='rainbow' tabName={tabName} showFav={false} /></div>




                    <Item itemName='aboutUsDetail2' tabName={tabName} showFav={false} />

                    <Row style={{ background: 'white', margin: '0px', padding: '0px 8px 40px 8px' }}>

                        <div className='subtitle'> {t('navigator.family.contactname1')}</div>

                        <a href={'mailto:' + Contacts['navigator.family.contactemail1']} >
                            <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail1']}</div></a>
                        <a href={'tel:' + Contacts['navigator.family.contactphone1']} className='phone-link text-orange' >  <PhoneIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} /> {Contacts['navigator.family.contactphone1']}</a>
                        <br></br> <br></br>
                        <a href={'mailto:' + Contacts['navigator.family.contactemail2']} >
                            <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail2']}</div></a>
                        <a href={'tel:' + Contacts['navigator.family.contactphone2']} className='phone-link text-orange' >  <PhoneIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} /> {Contacts['navigator.family.contactphone2']}</a>


                    </Row>

                    <Item itemName='aboutUsDetail3' tabName={tabName} showFav={false} />


                    <Row style={{ paddingTop: '0px', background: 'white' }}  >
                        <Col span={24} >
                            <img src={logo3} alt='logo' style={{ width: '100%', padding: '16px' }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '0px', background: 'white' }} type="flex" justify="space-around" align="middle" >

                        <Col span={12} >
                            <img src={logo1} alt='logo' style={{ width: '100%', padding: '16px' }} />
                        </Col>
                        <Col span={12} >
                            <img src={logo2} alt='logo' style={{ width: '100%', padding: '16px' }} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '8px', marginBottom: '24px', background: 'white' }} type="flex" justify="space-around" align="middle" >

                        <Col span={12} >
                            <img src={logo4} alt='logo' style={{ width: '100%', padding: '16px' }} />
                        </Col>
                        {/* <Col span={12} >
                            <img src={logo5} alt='logo' style={{ width: '100%', padding: '16px' }} />
                        </Col> */}

                    </Row>
                </div>
            </MobileLayout>;


        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AboutUs));
