import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import AppLayout from '../hoc/AppLayout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Item from './Items/item'
import backgroundImage from '../images/background.png';
import backgroundImageBrowser from '../images/background.png';
import UpIcon from '@material-ui/icons/ArrowUpward';
import { Row, Col } from 'antd';
import Label from '../elements/Label';
import * as actions from '../actions/menuSelection';


class Support extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        bottom: 10,
        sectionName: '',
        windowHeight: '100vh',
        pageHeight: window.innerHeight,
        pageWidth: window.innerWidth,
    };

    componentDidMount() {
        const { sectionName } = this.props;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" })
        };
        this.setState({ sectionName: sectionName, });

        let isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        if (isInStandaloneMode() && isMobileOnly) {
            this.setState({ windowHeight: '100vh', });
        }
        else {
            this.setState({ windowHeight: window.innerHeight, });
        }

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };


    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };


    render() {
        const { pageWidth } = this.state;

        const { t } = this.props;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" })
        };


        const tabName = 'support';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';

        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else {
            if (isMobileOnly)
                sectionName = "#top";
            else
                sectionName = "#top*";
        }

        if (this.props.mainMenuKey !== tabName || (this.props.mainMenuKey === tabName && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }

        let pageName = 'home';
        let pageTitle = 'Home';

        let page = null;
        page = <React.Fragment>
            {isMobileOnly || pageWidth < 768 ?
                <MobileLayout pageName={pageName} pageTitle={pageTitle}  >
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage})` }}  >
                        <div className='pageContent'>
                            <div id="top" />

                            <Item itemName='supportTitle' tabName={tabName} showFav={false} />

                            <div style={{ paddingBottom: '40px' }}>
                                <Link to="support#childcare" style={{ color: 'black' }} > <Item itemName='supportChildcare' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#earlyintervention" style={{ color: 'black' }} > <Item itemName='supportEarlyintervention' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#family" style={{ color: 'black' }} > <Item itemName='supportFamily' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#health" style={{ color: 'black' }} > <Item itemName='supportHealth' tabName={tabName} showFav={false} /></Link>
                            </div>


                            <div id="childcare" />
                            <Item itemName='childcare' tabName={tabName} showFav={false} />
                            <Item itemName='childcare.resource' tabName={tabName} showFav={true} />
                            <Col span={16} offset={8} style={{ marginBottom: '40px' }}>
                                <Item itemName='girl1' tabName={tabName} showFav={false} description='image.girl.1' />
                            </Col>


                            <div style={{ position: 'relative' }}>
                                <div id="earlyintervention" style={{ position: 'absolute', top: '40px' }} />
                            </div>
                            <Row>
                                <div className='arrow-float-right'> <Link to="support#top" ><UpIcon className='upIcon' /> </Link></div>
                                <Item itemName='earlyintervention' tabName={tabName} showFav={false} />
                            </Row>
                            <Item itemName='earlyintervention.familysmart' tabName={tabName} showFav={true} />
                            <Item itemName='earlyintervention.familysupport' tabName={tabName} showFav={true} />
                            <Item itemName='earlyintervention.childdevelopment' tabName={tabName} showFav={true} />

                            <div id="family" />

                            <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} style={{ paddingTop: '24px' }} />

                            <Row style={{ marginTop: '40px' }}>
                                <div className='arrow-float-right'> <Link to="support#top" ><UpIcon className='upIcon' /> </Link></div>
                                <Item itemName='family' tabName={tabName} showFav={false} />
                            </Row>

                            <Item itemName='family.childdevelopment' tabName={tabName} showFav={true} />
                            <Item itemName='family.encompass' tabName={tabName} showFav={true} />
                            <Item itemName='family.library' tabName={tabName} showFav={true} />
                            <Item itemName='family.immigrant' tabName={tabName} showFav={true} />
                            <Item itemName='family.laleche' tabName={tabName} showFav={true} />
                            <Item itemName='family.community' tabName={tabName} showFav={true} />
                            <Item itemName='family.SD35' tabName={tabName} showFav={true} />
                            <Item itemName='family.aboriginal' tabName={tabName} showFav={true} />
                            <Item itemName='family.metis' tabName={tabName} showFav={true} />
                            <Item itemName='family.postpartum' tabName={tabName} showFav={true} />

                            <div style={{ position: 'relative' }}>
                                <div id="health" style={{ position: 'absolute', top: '0px' }} />
                            </div>
                            <Row>
                                <Col span={12} style={{ marginBottom: '40px' }}>
                                    <Item itemName='boy2' tabName={tabName} showFav={false} description='image.boy.2' />
                                </Col>
                            </Row>
                            <Row>
                                <div className='arrow-float-right'> <Link to="support#top" ><UpIcon className='upIcon' /> </Link></div>
                                <Item itemName='health' tabName={tabName} showFav={false} />
                            </Row>

                            <Item itemName='health.foodbanklangley' tabName={tabName} showFav={true} />
                            <Item itemName='health.foodbankaldergrove' tabName={tabName} showFav={true} />
                            <Item itemName='health.publichealth' tabName={tabName} showFav={true} />
                            <Item itemName='health.raphael' tabName={tabName} showFav={true} />
                            <Item itemName='health.foodbanksources' tabName={tabName} showFav={true} />
                            <Row>
                                <Col span={22} offset={2} style={{ marginBottom: '40px' }}>
                                    <Item itemName='girl2' tabName={tabName} showFav={false} description='image.girl.2' />
                                </Col>
                            </Row>
                            <Row>
                                <div className='arrow-float-right'> <Link to="support#top" ><UpIcon className='upIcon' /> </Link></div>
                            </Row>
                        </div>
                    </div>
                </MobileLayout>
                : null}


            {pageWidth >= 1024 ?
                <AppLayout >
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImageBrowser})` }}   >
                        <div style={{ margin: pageWidth > 1600 ? '0px 180px 0px 180px' : '0px' }}>
                            <div className='web-content'>
                                <div style={{ position: 'relative' }}>
                                    <div id="top*" style={{ position: 'absolute', top: pageWidth < 1200 ? '-200px' : '-200px' }} />
                                </div>

                                <Row style={{ paddingTop: '24px' }}>
                                    <Col xs={24} sm={24} md={11} lg={24} xl={24} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='supportTitle1' tabName={tabName} showFav={false} />
                                    </Col>
                                </Row>


                                <Row style={{ paddingTop: '16px' }}>
                                    <Col span={6} offset={2} >
                                        <Item itemName='supportTitle2' tabName={tabName} showFav={false} />
                                        <Link to="support#childcare*" style={{ color: 'black' }} > <Item itemName='supportChildcare' tabName={tabName} showFav={false} /></Link>
                                        <Link to="support#earlyintervention*" style={{ color: 'black' }} > <Item itemName='supportEarlyintervention' tabName={tabName} showFav={false} /></Link>
                                        <Link to="support#family*" style={{ color: 'black' }} > <Item itemName='supportFamily' tabName={tabName} showFav={false} /></Link>
                                        <Link to="support#health*" style={{ color: 'black' }} > <Item itemName='supportHealth' tabName={tabName} showFav={false} /></Link>
                                    </Col>

                                    <Col span={12} offset={2} style={{ paddingTop: '40px' }}>
                                        <Item itemName='childcare' tabName={tabName} showFav={false} /><br />

                                        <Col span={12}  >
                                            <Item itemName='childcare.resource' tabName={tabName} showFav={true} />
                                        </Col>
                                        <Col span={10} offset={2} style={{ textAlign: 'center' }} >
                                            <Item itemName='girl1' tabName={tabName} showFav={false} description='image.girl.1' />
                                        </Col>

                                    </Col>

                                </Row>

                                <div style={{ position: 'relative' }}>
                                    <div id="earlyintervention*" style={{ position: 'absolute', top: '-140px' }} />
                                </div>
                                <Row style={{ paddingTop: '60px' }}>
                                    <Col span={20} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='earlyintervention' tabName={tabName} showFav={false} />
                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='earlyintervention.familysmart' tabName={tabName} showFav={true} />
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                        <Item itemName='earlyintervention.familysupport' tabName={tabName} showFav={true} />

                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                        <Item itemName='earlyintervention.childdevelopment' tabName={tabName} showFav={true} />

                                    </Col>
                                </Row>


                                <div style={{ position: 'relative' }}>
                                    <div id="family*" style={{ position: 'absolute', top: '-140px' }} />
                                </div>
                                <Row style={{ paddingTop: '80px' }}>
                                    <Col span={20} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='family' tabName={tabName} showFav={false} />
                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='family.childdevelopment' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.encompass' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.library' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.immigrant' tabName={tabName} showFav={true} />

                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                        <Item itemName='family.laleche' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.SD35' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.community' tabName={tabName} showFav={true} />
                                        <br />
                                        <br />
                                        <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />
                                        <br />
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                     
                                        <Item itemName='family.aboriginal' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.metis' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='family.postpartum' tabName={tabName} showFav={true} />
                     
                                    </Col>
                                </Row>
                                <div style={{ position: 'relative' }}>
                                    <div id="health*" style={{ position: 'absolute', top: '-140px' }} />
                                </div>

                                <Row style={{ paddingTop: '60px' }}>
                                    <Col span={20} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='health' tabName={tabName} showFav={false} />
                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 2} >
                                        <Item itemName='health.foodbanklangley' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='health.foodbankaldergrove' tabName={tabName} showFav={true} />
                                  
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                        <Item itemName='health.publichealth' tabName={tabName} showFav={true} />
                                        <br />
                                        <Item itemName='health.raphael' tabName={tabName} showFav={true} />

                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={6} xl={6} offset={pageWidth < 1200 ? 0 : 1} >
                                        <Item itemName='health.foodbanksources' tabName={tabName} showFav={true} />
                                        <Row>
                                            <Col span={18} offset={3}>
                                                <Item itemName='boy2' tabName={tabName} showFav={false} description='image.boy.2' />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '16px' }}>
                                    <Col span={20} offset={2} >
                                        <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                        <br />
                                        <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                        <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                    </Col>
                                </Row>

                            </div>
                        </div>
                    </div>
                </AppLayout> : null
            }

            {pageWidth < 1024 && pageWidth > 768 && !isMobileOnly ?
                <AppLayout >
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImageBrowser})` }}   >
                        <div className='web-content'>
                            <div style={{ position: 'relative' }}>
                                <div id="top*" style={{ position: 'absolute', top: pageWidth < 1200 ? '-200px' : '-200px' }} />
                            </div>

                            <Row style={{ paddingTop: '8px', paddingLeft: '40px' }}>
                                <Item itemName='supportTitle1' tabName={tabName} showFav={false} />
                            </Row>


                            <Row style={{ paddingTop: '16px' }}>
                                <Col span={11} offset={1} >
                                    <Item itemName='supportTitle2' tabName={tabName} showFav={false} />
                                    <Link to="support#childcare*" style={{ color: 'black' }} > <Item itemName='supportChildcare' tabName={tabName} showFav={false} /></Link>
                                    <Link to="support#earlyintervention*" style={{ color: 'black' }} > <Item itemName='supportEarlyintervention' tabName={tabName} showFav={false} /></Link>
                                    <Link to="support#family*" style={{ color: 'black' }} > <Item itemName='supportFamily' tabName={tabName} showFav={false} /></Link>
                                    <Link to="support#health*" style={{ color: 'black' }} > <Item itemName='supportHealth' tabName={tabName} showFav={false} /></Link>
                                    <br />  <br />   <br />

                                </Col>



                                <Col span={11} offset={1} style={{ paddingTop: '60px' }}>
                                    <Item itemName='childcare' tabName={tabName} showFav={false} />
                                    <Item itemName='childcare.resource' tabName={tabName} showFav={true} />
                                </Col>


                            </Row>
                            <Row style={{ paddingTop: '16px' }}>
                                <Col span={16} offset={4} style={{ textAlign: 'center' }}>
                                    <Item itemName='girl1' tabName={tabName} showFav={false} description='image.girl.1' />
                                </Col>
                            </Row>


                            <div style={{ position: 'relative' }}>
                                <div id="earlyintervention*" style={{ position: 'absolute', top: '-140px' }} />
                            </div>
                            <Row style={{ paddingTop: '60px' }}>
                                <Col span={22} offset={1} >
                                    <Item itemName='earlyintervention' tabName={tabName} showFav={false} />
                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={11} offset={1} >
                                    <Item itemName='earlyintervention.familysmart' tabName={tabName} showFav={true} />
                                </Col>
                                <Col span={11} offset={1} >
                                    <Item itemName='earlyintervention.familysupport' tabName={tabName} showFav={true} />
                                    <Item itemName='earlyintervention.childdevelopment' tabName={tabName} showFav={true} />

                                </Col>
                            </Row>


                            <div style={{ position: 'relative' }}>
                                <div id="family*" style={{ position: 'absolute', top: '-140px' }} />
                            </div>
                            <Row style={{ paddingTop: '80px' }}>
                                <Col span={22} offset={1} >
                                    <Item itemName='family' tabName={tabName} showFav={false} />
                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={11} offset={1} >
                                    <Item itemName='family.childdevelopment' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='family.encompass' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='family.library' tabName={tabName} showFav={true} />  <br />
                                    <Item itemName='family.immigrant' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='family.laleche' tabName={tabName} showFav={true} />


                                </Col>
                                <Col span={11} offset={1} >


                                    <Item itemName='family.SD35' tabName={tabName} showFav={true} />  <br />
                                    <Item itemName='family.community' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='family.aboriginal' tabName={tabName} showFav={true} />
                                    <br />
                                    <div style={{ margin: '0px 8px 40px 20px' }}>
                                        <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />
                                    </div>

                                    <Row>
                                        <Col span={18} offset={3}>
                                            <Item itemName='boy2' tabName={tabName} showFav={false} description='image.boy.2' />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>



                            <div style={{ position: 'relative' }}>
                                <div id="health*" style={{ position: 'absolute', top: '-140px' }} />
                            </div>

                            <Row style={{ paddingTop: '60px' }}>
                                <Col span={22} offset={1} >
                                    <Item itemName='health' tabName={tabName} showFav={false} />
                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>

                                </Col>
                            </Row>
                            <Row>
                                <Col span={11} offset={1} >
                                    <Item itemName='health.foodbanklangley' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='health.foodbankaldergrove' tabName={tabName} showFav={true} />
                                 
                                    <Row>
                                        <Col span={21} offset={3}>
                                            <Item itemName='girl2' tabName={tabName} showFav={false} description='image.girl2' />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={11} offset={1} >
                                    <Item itemName='health.publichealth' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='health.raphael' tabName={tabName} showFav={true} />
                                    <br />
                                    <Item itemName='health.foodbanksources' tabName={tabName} showFav={true} />

                                </Col>

                            </Row>
                            <Row style={{ paddingTop: '16px' }}>
                                <Col span={22} offset={1} >
                                    <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                    <br />
                                    <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </AppLayout> : null
            }



        </React.Fragment>;

        return (
            <React.Fragment>
                {menuRedirect}
                {page}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Support));
