import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import AppLayout from '../hoc/AppLayout/AppLayout'
import { isMobileOnly } from "react-device-detect";
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import * as actions from '../actions/menuSelection';
import Item from './Items/item'
import { Col, Row, Modal, Checkbox } from 'antd';
import UpIcon from '@material-ui/icons/ArrowUpward';
import backgroundImage4 from '../images/background.png';
import Label from '../elements/Label';
import AppleShare from '../images/AppleShare.png';
import AppleAddToHomeScreen from '../images/AppleAddtoHomeScreen.png';
import AppleAdd from '../images/AppleAdd.png';

class Home extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        sectionName: '',
        showInstallMessage: false,
        windowHeight: '100vh',
        pageWidth: window.innerWidth,
        pageHeight: window.innerHeight,
    };

    componentDidMount() {
        const { sectionName } = this.props;
        const { pageHeight } = this.state;

        let lang = localStorage.getItem('i18nextLng');
        lang = lang.substr(0, 2);
        if (lang) { this.setLanguage(lang); }
        else { this.setLanguage('en'); }

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };

        this.setState({ sectionName: sectionName, });

        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }
        // Detects if device is in standalone mode
        let isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        let showInstallMessage = localStorage.getItem('showInstallMessage');

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode() && !showInstallMessage) {
            this.setState({ showInstallMessage: true, });
        }


        if (isInStandaloneMode() && isMobileOnly) {
            this.setState({ windowHeight: '100vh', });
        }
        else {
            this.setState({ windowHeight: pageHeight, });
        }

        if (localStorage.getItem('loading')) {
            if (localStorage.getItem('loading') === 'true') {
                localStorage.removeItem('loading');
                if (isInStandaloneMode() && isMobileOnly) {
                    this.setState({ windowHeight: '100vh', });
                }
            }
        }

        window.addEventListener('resize', this.updateDimensions);


    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionName } = this.props;

        if (sectionName !== prevProps.sectionName)
            this.setState({ sectionName: sectionName, });


    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ pageWidth: window.innerWidth, pageHeight: window.innerHeight });
    };

    setLanguage = (language) => {
        const { i18n } = this.props;
        i18n.changeLanguage(language);
    };

    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {
        this.props.onMenuClick([pageName], pageName, sectionName);
    };

    handleInstallCancel = () => {
        this.setState({ showInstallMessage: false, });
    };

    handleDoNotShowInstallMessage = () => {
        localStorage.setItem('showInstallMessage', false);
    };

    render() {

        const { showInstallMessage, pageWidth } = this.state;
        const { t } = this.props;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };


        const tabName = 'home';
        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';
        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else {
            if (isMobileOnly)
                sectionName = "#top";
            else
                sectionName = "#top*";
        }

        if (this.props.mainMenuKey !== "home" || (this.props.mainMenuKey === "home" && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }

        let installModal = null;
        if (showInstallMessage === true)
            installModal = <Modal
                title={t('installApp')}
                visible={showInstallMessage}
                onCancel={this.handleInstallCancel}
                width={600}
                footer={null}
                centered
            >
                <div >
                    <Label type='Body1' caption={t('tapShare')}></Label><br />
                    <img src={AppleShare} height='80px' alt={t('tapShare')} ></img>
                    <br />
                    <Label type='Body1' caption={t('addtoHomeScreen')}></Label>
                    <br /><br />
                    <img src={AppleAddToHomeScreen} width='240px' alt={t('addtoHomeScreen')}></img>
                    <br /><br />
                    <Label type='Body1' caption={t('clickAdd')}></Label>
                    <br /><br />
                    <img src={AppleAdd} style={{ border: "thin solid lightgrey" }} width='auto' alt={t('addtoHomeScreen')}></img>
                    <br /><br />
                    <Checkbox onChange={() => this.handleDoNotShowInstallMessage()}>{t('noPrompt')}</Checkbox>
                </div>

            </Modal>;


        let page = null;
        page = <React.Fragment>
            {isMobileOnly || pageWidth < 768 ?
                <MobileLayout pageName='home' pageTitle='Home' >
                    <div className='pageBackground ' style={{ backgroundImage: `url(${backgroundImage4})` }} >
                        <div className='sectionContent'>
                            <div id="top" />
                            <Row><Item itemName='contactUsDetail' tabName={tabName} showFav={false} /></Row>
                            {/* <Row style={{ marginBottom: '16px' }}><Item itemName='message' tabName={tabName} showFav={false} /></Row> */}
                            <Link to="home#navigator" style={{ color: 'black' }} > <Item itemName='navigator' tabName={tabName} showFav={false} /></Link>
                            <Item itemName='hubTitle' tabName={tabName} showFav={false} />
                            <Link to="hubs#aldergrove" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'aldergove')}>
                                <Item itemName='aldergrove' tabName={tabName} showFav={false} />
                            </Link>
                            {/* <Link to="hubs#lcdouglas" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lcdouglas')}>   <Item itemName='lcdouglas' tabName={tabName} showFav={false} /> </Link> */}
                            <Link to="hubs#lccommunity" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lccommunity')}> <Item itemName='lccommunity' tabName={tabName} showFav={false} /></Link>
                            <Link to="hubs#willoughby" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'willoughby')} ><Item itemName='willoughby' tabName={tabName} showFav={false} /> </Link>
                            <br></br>
                            <Item itemName='supportServicesTitle' tabName={tabName} showFav={false} />
                            <Link to="support#childcare" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'childcare')}> <Item itemName='supportChildcare' tabName={tabName} showFav={false} /></Link>
                            <Link to="support#earlyintervention" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'earlyintervention')}> <Item itemName='supportEarlyintervention' tabName={tabName} showFav={false} /></Link>
                            <Link to="support#family" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'family')}> <Item itemName='supportFamily' tabName={tabName} showFav={false} /></Link>
                            <Link to="support#health" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'health')}> <Item itemName='supportHealth' tabName={tabName} showFav={false} /></Link>
                        </div>
                    </div>



                    <div className='pageBackground '  >
                        <div style={{ position: 'relative' }}>
                            <div id="navigator" style={{ position: 'absolute', top: '60px' }} />
                        </div>
                        <Item itemName='navigator.heading' tabName={tabName} showFav={false} />
                        <div className='up-arrow-float-right'> <Link to="home#top" ><UpIcon className='upIcon' /> </Link></div>
                        <div className='sectionContent'>
                            <Item itemName='navigator.family' tabName='' showFav={false} />
                        </div>
                        <div className='up-arrow-float-right'> <Link to="home#top" ><UpIcon className='upIcon' /> </Link></div>
                    </div>

                </MobileLayout>
                :
                <AppLayout pageName='home' pageTitle='Home' >
                    <div style={{ position: 'relative' }}>
                        <div id="top*" style={{ position: 'absolute', top: '-200px' }} />
                    </div>

                    {/* <Item itemName='message' tabName={tabName} showFav={false} /> */}

                    <div style={{ margin: pageWidth > 1600 ? '24px 180px 0px 180px' : '0px' }}>
                        <div className='pageBackground web-content' style={{ backgroundImage: `url(${backgroundImage4})` }}   >
                            {/* <div className='pageBackground web-content' > */}


                            <Col span={pageWidth < 1200 ? 10 : 5} offset={pageWidth < 1200 ? 1 : 2} >

                                <Item itemName='hubTitle' tabName={tabName} showFav={false} />
                                <Item itemName='hubDescription' tabName={tabName} showFav={false} />
                                <br />
                                <Link to="hubs#aldergrove" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'aldergrove')}>
                                    <Item itemName='aldergrove' tabName={tabName} showFav={false} />
                                </Link>
                                {/* <Link to="hubs#lcdouglas" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lcdouglas')}>   <Item itemName='lcdouglas' tabName={tabName} showFav={false} /> </Link> */}
                                <Link to="hubs#lccommunity" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'lccommunity')}> <Item itemName='lccommunity' tabName={tabName} showFav={false} /></Link>
                                <Link to="hubs#willoughby" style={{ color: 'black' }} onClick={() => this.gotoPageSection('hubs', 'willoughby')} ><Item itemName='willoughby' tabName={tabName} showFav={false} /> </Link>


                                {pageWidth < 1200 ?
                                    <Row style={{ marginTop: '24px' }}>
                                        <Col span={10} >
                                            <Item itemName='rainbow' tabName={tabName} showFav={false} />
                                        </Col>
                                        <Col span={13} offset={1}>
                                            <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />
                                        </Col>

                                    </Row>
                                    : <Item itemName='acknowledge.firstnations' tabName={tabName} showFav={false} />}



                            </Col>

                            <Col span={pageWidth < 1200 ? 10 : 6} offset={pageWidth < 1200 ? 2 : 2} style={{ paddingBottom: '40px' }}>
                                <Item itemName='navigatorTitle' tabName={tabName} showFav={false} />
                                <Item itemName='navigator.family' tabName={tabName} showFav={false} />
                            </Col>

                            <Col span={pageWidth < 1200 ? 10 : 5} offset={pageWidth < 1200 ? 1 : 2} style={{ paddingTop: pageWidth < 1200 ? '40px' : '0px' }}>
                                <Item itemName='supportServicesTitle' tabName={tabName} showFav={false} />
                                <Item itemName='supportDescription' tabName={tabName} showFav={false} />
                                <br />

                                <Link to="support#childcare*" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'childcare*')}> <Item itemName='supportChildcare' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#earlyintervention*" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'earlyintervention*')}> <Item itemName='supportEarlyintervention' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#family*" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'family*')}> <Item itemName='supportFamily' tabName={tabName} showFav={false} /></Link>
                                <Link to="support#health*" style={{ color: 'black' }} onClick={() => this.gotoPageSection('support', 'health*')}> <Item itemName='supportHealth' tabName={tabName} showFav={false} /></Link>
                                {pageWidth >= 1200 ? <React.Fragment><br /> <br /> <br />
                                    <Col span={20} offset={2}>
                                        <Item itemName='girl3' description='image.girl.1' tabName={tabName} showFav={false} />
                                    </Col><br /> <br /> <br /></React.Fragment> : <div style={{ marginBottom: '40px' }}></div>}
                            </Col>

                            {pageWidth < 1200 ? <Col md={11} offset={2} style={{ paddingTop: '100px' }}>
                                <Item itemName='girl3' description='image.girl.1' tabName={tabName} showFav={false} />
                            </Col> : null}

                            <Row style={{ paddingTop: '16px' }}>
                                <Col span={pageWidth < 1200 ? 22 : 20} offset={pageWidth < 1200 ? 1 : 2} >
                                    <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />     <Link to="terms" style={{ color: 'black', paddingLeft: '16px', fontWeight: 300, fontSize: '12px', fontFamily: 'roboto' }} onClick={() => this.gotoPageSection('terms', '')}> {t('terms')}  </Link>
                                    <br />
                                    <Label type='Body2' caption={'Designed by'} /> <a href='https:\\betterworldsoftware.ca' target="_blank" rel="noopener noreferrer" >Better World Software Inc.</a><br />


                                    <div className='arrow-float-right'> <Link to="support#top*" ><UpIcon className='upIcon' /> </Link></div>
                                </Col>
                            </Row>


                        </div>
                    </div>
                </AppLayout>
            }
        </React.Fragment>;

        return (
            <React.Fragment>
                {menuRedirect}
                {page}
                {installModal}
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName,
        searchString: state.menuSelection.searchString
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Home));
