import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-ca';

import enUS from 'antd/lib/locale-provider/en_US';

export const getAntLocale = locale => {
    switch (locale) {
      
        default:
            moment.locale('en-ca');
            return enUS;
    }
};

export const getDefaultLocale = locales => locales.find(one => one.isDefault).code;