import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as actions from '../../actions/menuSelection';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { isTablet, isMobileOnly } from "react-device-detect";

import FaceBookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import RightArrowIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

// import AssetBee from '../../images/Asset-Bee.png';
import AssetGirl1 from '../../images/Asset-Girl1.png';
import AssetGirl2 from '../../images/Asset-Girl2.png';
import AssetGirl3 from '../../images/Asset-Girl3.png';

import AssetGirl5 from '../../images/Asset-Girl5.png';
import AssetGirl6 from '../../images/Asset-Girl6.png';

import AssetBoy1 from '../../images/Asset-Boy1.png';
import AssetBoy2 from '../../images/Asset-Boy2.png';
import AssetBoyBalloon from '../../images/Asset-BoyBalloon.png';

import AssetBug from '../../images/Asset-Bug.png';
import AssetFlower1 from '../../images/Asset-Flower1.png';
import AssetFlower2 from '../../images/Asset-Flower2.png';
import AssetRainbow from '../../images/Asset-Rainbow.png';

import AssetTextBoxArrow from '../../images/Asset-TextBox-Arrow.png';
import AssetTextBoxArrowGreen from '../../images/Asset-Arrow-Green.png';
import AssetTextBoxArrowOrange from '../../images/Asset-TextBox-Arrow-Orange.png';
import AssetTextBoxArrowPink from '../../images/Asset-TextBox-Arrow-Pink.png';

import AssetBlueBoard from '../../images/Asset-Blue-Board.png';
import AssetPinkBoard from '../../images/Asset-Pink-Board.png';
import AssetOrangeBoard from '../../images/Asset-Orange-Board.png';
import AssetPurpleBoard from '../../images/Asset-Purple-Board.png';
import AssetOrangeBoardLong from '../../images/Asset-Orange-Board-Long.png';

import AssetBanner from '../../images/Asset-Banner.png';
import AssetBannerPink from '../../images/Asset-Banner-Pink.png';
import AssetBannerPink2 from '../../images/Asset-Banner-Pink2.png';
import AssetBannerOrange from '../../images/Asset-Banner-Orange.png';
import AssetBannerPurple from '../../images/Asset-Banner-Purple.png';
import AssetBannerBlueMobile from '../../images/Asset-Banner-Blue-Mobile.png';

import AssetPhone from '../../images/Asset-Phone.png';
import AssetPhoneGreen from '../../images/Asset-Phone-Green.png';

import Favourite from './favourite';
import ItemTitle from './itemTitle';
import * as Contacts from '../../assets/contacts.json';

const faceBookColor = '#3b5998';

class Item extends Component {
    state = {
        showPreferences: false,
        showFavourites: false,
        favStatus: false,
    };

    componentDidMount() {
        const { favList, itemName } = this.props;
        //check if item is in Favourite List
        if (favList !== '[]' && favList !== null && itemName !== undefined && favList !== undefined) {
            let favStatus = favList.indexOf(itemName) > -1;
            this.setState({ favStatus: favStatus })
        }
        else {
            this.setState({ favStatus: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { favList, itemName } = this.props;

        if (favList !== prevProps.favList && favList !== undefined) {
            let favStatus = favList.indexOf(itemName) > -1;
            this.setState({ favStatus: favStatus })
        }
    }

    setMenu = (menuName) => {
        const { onMenuClick, onMobileMenuClick, onMobileToolbarClick } = this.props;
        onMenuClick('[' + menuName + ']', menuName);
        onMobileMenuClick(menuName);

        if (menuName === 'home')
            onMobileToolbarClick(0);
        if (menuName === 'support')
            onMobileToolbarClick(1);
    };

    setFavouriteItem = (itemName, status) => {
        const { setFav } = this.props;
        setFav(itemName, status);
    };


    render() {

        const { t, i18n, itemName, tabName, description, showFav } = this.props;
        const { favStatus } = this.state;

        let mobileItem = null;

        switch (itemName) {
            case 'message':
                mobileItem = <div className='important-message'> {t('message')} <a href="https://langleychildren.com/langley-covid-resources/" target="_blank" rel="noopener noreferrer" style={{color:'lightblue'}} > langleychildren.com/langley-covid-resources </a>  </div>;
                break;

            case 'search.noresults.title':
                mobileItem = <div className='results-box text-darkblue'> {t('search.noresults.title')}</div>;
                break;

            case 'bug':
                mobileItem =
                    <img src={AssetBug} alt={t('image.bug')} style={{ width: '80px' }} />;
                break;

            case 'flower1':
                mobileItem =
                    <img src={AssetFlower1} alt={t('image.flower')} style={{ width: '80px' }} />;
                break;

            case 'flower2':
                mobileItem =
                    <img src={AssetFlower2} alt={t('image.flower')} style={{ width: '80px' }} />;
                break;


            case 'rainbow':
                mobileItem = <img src={AssetRainbow} alt={t('image.rainbow')} style={{ margin: '0px', padding: '0px', width: '100%', maxWidth: '350px' }} />
                    ;
                break;

            case 'favs.nofavs.title':
                mobileItem = <React.Fragment>
                    {tabName === 'favs' ? <div className='results-box text-darkblue'> {t('favs.nofavs.title')}</div> : null}
                </React.Fragment>;
                break;


            case 'hubTitle':
                mobileItem = <div className='title text-darkblue'> {t('home.hub.title')}</div>;
                break;

            case 'aldergrove.heading':
                mobileItem = <div className='bg-purple' style={{ width: '100%' }}>
                    <div className='hub-heading'>{t('aldergrove.title')} </div>
                </div>;
                break;

            case 'aldergrove.heading.browser':
                mobileItem = <div className='bg-purple' style={{ width: 'auto' }}>
                    <div className='hub-heading-browser'>{t('aldergrove.title')}   - {t('aldergrove.locationname')}

                        <div style={{ float: 'right' }}> {Contacts['aldergrove.locationphone']}</div>
                    </div>
                </div>;
                break;



            case 'lcdouglas.heading':
                mobileItem = <div className='bg-bluegreen' style={{ width: '100%' }}>
                    <div className='hub-heading'> {t('home.hub.langleycity.title')}</div>
                </div>;
                break;

            case 'lcdouglas.heading.browser':
                mobileItem = <div className='bg-bluegreen' style={{ width: '100%' }}>
                    <div className='hub-heading-browser'> {t('home.hub.langleycity.title')} - {t('home.hub.langleycity.douglas')}

                        <div style={{ float: 'right' }}> {Contacts['lcdouglas.locationphone']}</div>
                    </div>
                </div>;
                break;


            case 'lccommunity.heading':
                mobileItem = <div className='bg-orange2' style={{ width: '100%' }}>
                    <div className='hub-heading'> {t('home.hub.langleycity.title')}</div>
                </div>;
                break;

            case 'lccommunity.heading.browser':
                mobileItem = <div className='bg-orange2' style={{ width: '100%' }}>
                    <div className='hub-heading-browser'> {t('home.hub.langleycity.title')} - {t('home.hub.langleycity.community')}
                        <div style={{ float: 'right' }}> {Contacts['lccommunity.locationphone']}</div>
                    </div>
                </div>;
                break;


            case 'willoughby.heading':
                mobileItem = <div className='bg-coral' style={{ width: '100%' }}>
                    <div className='hub-heading'> {t('home.hub.willoughby.title')}</div>
                </div>;
                break;

            case 'willoughby.heading.browser':
                mobileItem = <div className='bg-coral' style={{ width: '100%' }}>
                    <div className='hub-heading-browser'> {t('home.hub.willoughby.title')} - {t('willoughby.locationname')}
                        <div style={{ float: 'right' }}> {Contacts['willoughby.locationphone']}</div>
                    </div>
                </div>;
                break;

            case 'navigator.heading':
                mobileItem = <div className='bg-orange1' style={{ width: '100%' }}>
                    <div className='hub-heading bg-orange1'> {t('home.hub.familynavigator.title')}</div>
                </div>;
                break;

            case 'aldergrove':
                mobileItem = <div className="bigger-button bg-purple"  >
                    <div className='button-body'>
                        <div className='button-heading'> {t('home.hub.aldergrove.title')}</div>
                        <div className='button-detail'> {t('aldergrove.locationname')}</div>

                    </div>
                </div>;
                break;
            case 'lcdouglas':
                mobileItem = <div className="bigger-button bg-bluegreen "  >
                    <div className='button-body2'>
                        <div className='button-heading'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail'> {t('home.hub.langleycity.douglas')}</div>
                    </div>
                    {/* <div className='button-arrow'><RightArrowIcon style={{ fontSize: '1.5em' }} /></div> */}
                </div>;
                break;
            case 'lccommunity':
                mobileItem = <div className="bigger-button bg-orange1" >
                    <div className='button-body2'>
                        <div className='button-heading'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail'> {t('home.hub.langleycity.community')}</div>
                    </div>
                </div>;
                break;

            case 'willoughby':
                mobileItem = <div className="bigger-button bg-coral" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('home.hub.willoughby.title')}</div>
                        <div className='button-detail'> {t('willoughby.locationname')}</div>
                    </div>
                </div>;
                break;

            case 'aldergroveSmall':
                mobileItem = <div className="small-button bg-purple"  >
                    <div className='button-body-small'>
                        <div className='button-heading-small'> {t('home.hub.aldergrove.title')}</div>
                        <div className='button-detail-small'> {t('aldergrove.locationname')}</div>
                    </div>
                </div>;
                break;
            case 'lcdouglasSmall':
                mobileItem = <div className="smaller-button bg-bluegreen "  >
                    <div className='button-body2'>
                        <div className='button-heading-small'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail-small'> {t('home.hub.langleycity.douglas')}</div>
                    </div>
                </div>;
                break;
            case 'lccommunitySmall':
                mobileItem = <div className="smaller-button bg-orange1" >
                    <div className='button-body2'>
                        <div className='button-heading-small'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail-small'> {t('home.hub.langleycity.community')}</div>
                    </div>
                </div>;
                break;

            case 'willoughbySmall':
                mobileItem = <div className="small-button bg-coral" >
                    <div className='button-body-small'>
                        <div className='button-heading-small'> {t('home.hub.willoughby.title')}</div>
                        <div className='button-detail-small'> {t('willoughby.locationname')}</div>
                    </div>
                </div>;
                break;

            case 'aldergroveHub':
                mobileItem = <div className="small-button bg-purple"  >
                    <div className='button-body'>
                        <div className='button-heading'> {t('home.hub.aldergrove.title')}</div>
                        <div className='button-detail'> {t('aldergrove.locationname')}</div>
                    </div>
                </div>;
                break;
            case 'lcdouglasHub':
                mobileItem = <div className="smaller-button bg-bluegreen "  >
                    <div className='button-body2'>
                        <div className='button-heading'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail'> {t('home.hub.langleycity.douglas')}</div>
                    </div>
                </div>;
                break;
            case 'lccommunityHub':
                mobileItem = <div className="smaller-button bg-orange1" >
                    <div className='button-body2'>
                        <div className='button-heading'> {t('home.hub.langleycity.title')}</div>
                        <div className='button-detail'> {t('home.hub.langleycity.community')}</div>
                    </div>
                </div>;
                break;

            case 'willoughbyHub':
                mobileItem = <div className="small-button bg-coral" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('home.hub.willoughby.title')}</div>
                        <div className='button-detail'> {t('willoughby.locationname')}</div>
                    </div>
                </div>;
                break;

            case 'supportServicesTitle':
                mobileItem = <div className='title text-darkblue'> {t('home.support.title')}</div>;
                break;

            case 'navigatorTitle':
                mobileItem = <div className='title text-darkblue'> {t('home.hub.familynavigator.title')}</div>;
                break;

            case 'navigator':
                mobileItem = <div className="bigger-button bg-orange2 "  >
                    <div className='button-body2'>
                        <img src={AssetRainbow} alt={t('image.rainbow')} style={{ maxWidth: '140px', margin: '0px', float: 'right', padding: '0px' }} />
                        <div className='button-heading'> {t('home.hub.familynavigator.title')}</div>
                        <div className='button-detail'> {t('home.hub.familynavigator.subtitle')}</div>
                    </div>
                </div>;
                break;

            case 'support':
                mobileItem = <div className="bigger-button bg-pink" onClick={() => this.setMenu('support')} >
                    <div className='button-body2'>
                        <div className='button-heading'> {t('home.hub.support.title')}</div>
                        <div className='button-detail'> {t('home.hub.support.detail')}</div>
                    </div>
                    <div className='button-arrow'><RightArrowIcon style={{ fontSize: '1.5em' }} /></div>
                </div>;
                break;


            case 'supportChildcare':
                mobileItem = <div className="big-button bg-pink" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('support.childcare.title')}</div>
                    </div>
                </div>;
                break;


            case 'supportEarlyintervention':
                mobileItem = <div className="big-button bg-pink1" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('support.earlyintervention.title')}</div>
                    </div>
                </div>;
                break;


            case 'supportFamily':
                mobileItem = <div className="big-button bg-pink2" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('support.family.title')}</div>
                    </div>
                </div>;
                break;

            case 'supportHealth':
                mobileItem = <div className="big-button bg-pink3" >
                    <div className='button-body'>
                        <div className='button-heading'> {t('support.health.title')}</div>
                    </div>
                </div>;
                break;

            case 'childcare':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPink2})` }} >
                            {t('support.childcare.title')}
                        </div>
                    </div>


                break;


            case 'earlyintervention':

                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPink2})` }} >
                            {t('support.earlyintervention.title')}
                        </div>
                    </div>;
                break;

            case 'family':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPink2})` }} >
                            {t('support.family.title')}
                        </div>
                    </div>;


                break;
            case 'health':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPink2})` }} >
                            {t('support.health.title')}
                        </div>
                    </div>;

                break;

            case 'hubDescription':
                mobileItem = <div className='paragraph'> {t('home.p1')}</div>;
                break;

            case 'supportDescription':
                mobileItem = <div className='paragraph'> {t('support.p1')}</div>;
                break;



            case 'aboutUsTitle':
                mobileItem = <div className='title text-darkblue'>{t('home.aboutus.title')}</div>;
                break;

            case 'aboutUsTitleMobile':
                mobileItem = <div className='title text-darkblue'>{t('home.aboutus.title.mobile')}</div>;
                break;

            case 'aboutUsDetail':
                mobileItem = <div style={{ backgroundColor: 'white', border: 'dotted 2px lightgrey', padding: '8px', marginBottom: '24px' }}>
                    <div className='paragraph'> {t('home.p1')}</div>

                    <div className='information-box bg-lightgreen'>
                        {t('home.p2.t1')}
                    </div>

                    <div className='paragraph'> {t('home.aboutus.detail1')}</div>

                    <div className='information-box bg-lightgreen'>
                        {t('home.aboutus.detail2')}
                    </div>

                    <div className='paragraph'> {t('home.goals.title')}</div>
                    <ul className='list'>
                        <li>  {t('home.goals.detail1')}</li>
                        <li>  {t('home.goals.detail2')}</li>
                    </ul>
                </div>;
                break;

            case 'aboutUsDetail1':
                mobileItem = <div style={{ backgroundColor: 'white', border: isMobileOnly ? 'none' : 'dotted 2px lightgrey', padding: '8px', marginBottom: '24px' }}>
                    <div className='paragraph'> {t('home.p1')}</div>

                    <div className='info-box bg-blue'>
                        {t('home.p2.t1')}
                    </div>


                </div>;
                break;


            case 'aboutUsDetail2':
                mobileItem = <div style={{ backgroundColor: 'white', border: isMobileOnly ? 'none' : 'dotted 2px lightgrey', padding: '8px', marginBottom: '24px' }}>
                    <div className='paragraph'> {t('home.aboutus.detail1')}</div>

                    <div className='info-box bg-darkblue' style={{ color: 'whitesmoke' }}>
                        {t('home.aboutus.detail2')}
                    </div>

                </div>;
                break;

            case 'aboutUsDetail3':
                mobileItem = <div style={{ backgroundColor: 'white', border: isMobileOnly ? 'none' : 'dotted 2px lightgrey', padding: '8px', marginBottom: '24px' }}>

                    <div className='paragraph'> {t('home.goals.title')}</div>
                    <ul className='list-about'>
                        <li>  {t('home.goals.detail1')}</li>
                        <li>  {t('home.goals.detail2')}</li>
                    </ul>
                </div>;
                break;




            case 'contactUsDetail':
                mobileItem =
                    <div style={{ width: '100%', textAlign: 'left', color: faceBookColor, margin: '0px', padding: '0px', display: 'block' }}>
                        <div style={{ fontWeight: 300, opacity: .87, fontSize: isTablet ? '1.25em' : '1.5em', marginTop: '4px', marginLeft: '8px', float: 'left', color: 'black' }}>

                            <a href={"tel:" + Contacts['navigator.family.contactphone1']} style={{ cursor: 'pointer', color: faceBookColor }}  >
                                {Contacts['navigator.family.contactphone1']}
                            </a>
                        </div>

                        <div style={{ float: 'right' }}>

                            <a href={"https://www.facebook.com/langearlyyears/"} target="_blank" rel="noopener noreferrer" style={{ color: faceBookColor, fontWeight: 300, opacity: .87, paddingTop: '0px', zIndex: 99999, cursor: 'pointer' }} >
                                <FaceBookIcon style={{ fontSize: '3em', marginLeft: '8px', marginTop: '0px' }} />
                            </a>
                            <a href={"https://www.instagram.com/langleyearlyyears/"} target="_blank" rel="noopener noreferrer" style={{ color: 'black', fontWeight: 300, opacity: .87, paddingTop: '0px', zIndex: 99999, cursor: 'pointer' }} >
                                <InstagramIcon style={{ fontSize: '3em', marginLeft: '8px', marginTop: '0px' }} />
                            </a>
           
                        </div>
        
                    </div >

                    ;
                break;

            case 'terms':
                mobileItem =
                    <div style={{ backgroundColor: 'white' }}>
                        <div className='paragraph'>{t('terms.p1')}</div><br />
                        <div className='paragraph'>{t('terms.p1.1')}</div><br />
                        <div className='bullet-item-terms'>{t('terms.point1')}</div>
                        <div className='bullet-item-terms'>{t('terms.point2')}</div>
                        <div className='bullet-item-terms'>{t('terms.point3')}</div>
                        <div className='bullet-item-terms'>{t('terms.point4')}</div>
                        <div className='bullet-item-terms'>{t('terms.point5')}</div>
                        <br />
                        <div className='paragraph'>{t('terms.p2')}</div><br />
                        <div className='paragraph'>{t('terms.p3')}</div><br />
                        <div className='paragraph'>{t('terms.p4')} <br />
                            <a href={'mailto:' + Contacts['navigator.family.contactemail1']} >
                                <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail1']}</div></a>
                        </div>



                    </div>;
                break;

            case 'terms.title':
                mobileItem = <div className='title text-darkblue'>{t('terms.title')}</div>;
                break;

            case 'terms.part1':
                mobileItem =
                    <div style={{ backgroundColor: 'white' }}>


                        <div className='paragraph'>{t('terms.p1')}</div><br />

                        <div className='paragraph'>{t('terms.p1.1')}</div><br />

                        <div className='bullet-item-terms'>{t('terms.point1')}</div>
                        <div className='bullet-item-terms'>{t('terms.point2')}</div>
                        <div className='bullet-item-terms'>{t('terms.point3')}</div>
                        <div className='bullet-item-terms'>{t('terms.point4')}</div>
                        <div className='bullet-item-terms'>{t('terms.point5')}</div>



                    </div>;
                break;

            case 'terms.part2':
                mobileItem =
                    <div style={{ backgroundColor: 'white' }}>

                        <div className='paragraph'>{t('terms.p2')}</div><br />
                        <div className='paragraph'>{t('terms.p3')}</div><br />
                        <div className='paragraph'>{t('terms.p4')} <br /><br />
                            <a href={'mailto:' + Contacts['navigator.family.contactemail1']} >
                                <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail1']}</div></a>

                        </div>


                    </div>;
                break;

            case 'girl1':
                let girldescription = t(description.toString());
                mobileItem =
                    <img src={AssetGirl1} alt={girldescription} className=' vert-move' style={{ width: '100%', maxWidth: '400px' }} />;
                break;

            case 'girl3':
                let girl3description = t(description.toString());
                mobileItem =
                    <img src={AssetGirl3} alt={girl3description} className=' vert-move' style={{ width: '100%', maxWidth: '350px' }} />;
                break;

            case 'navigator.family':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('navigator.family')}  </div> : null}
                        {tabName === '' || isMobileOnly === false ?

                            <React.Fragment>
                                <div style={{ padding: tabName === 'search' || tabName === 'favs' ? "0px 16px 16px 16px" : "0px" }}>
                                    <div className='paragraph'> {t('navigator.family.p1')}</div>
                                    <br></br>

                                    {tabName !== 'search' && tabName !== 'favs' ?
                                        <React.Fragment>
                                            <div className='family-box-background' style={{ backgroundImage: `url(${AssetOrangeBoardLong})` }} >
                                                <p> {t('navigator.family.subtitle')}</p>
                                                <div className='bullet-item'>{t('navigator.family.point1')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point2')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point3')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point4')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point5')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point6')}</div>
                                                <div className='bullet-item'>{t('navigator.family.point7')}</div>
                                            </div>

                                            <br></br>
                                            <div className='paragraph'> {t('navigator.family.p2')}</div> <br></br>
                                            <div className='paragraph'> {t('navigator.family.p3')}</div>
                                            <br></br>
                                        </React.Fragment>
                                        : null}

                                    <div className='subtitle'> {t('navigator.family.contactname1')}</div>

                                    <a href={'mailto:' + Contacts['navigator.family.contactemail1']} >
                                        <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail1']}</div></a>
                                    <a href={'tel:' + Contacts['navigator.family.contactphone1']} className='phone-link text-orange' >  <PhoneIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} /> {Contacts['navigator.family.contactphone1']}</a>
                                    <br />   <br />
                                    <a href={'mailto:' + Contacts['navigator.family.contactemail2']} >
                                        <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail2']}</div></a>
                                    <a href={'tel:' + Contacts['navigator.family.contactphone2']} className='phone-link text-orange' >  <PhoneIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} /> {Contacts['navigator.family.contactphone2']}</a>



                                </div>
                            </React.Fragment>
                            :
                            <ItemTitle itemName='navigator.family.summary' favStatus={favStatus} tabName={tabName} />
                        }

                    </React.Fragment>;

                break;

            case 'navigator.toolbar.contact':
                mobileItem =
                    <a href={'tel:' + Contacts['navigator.family.contactphone1']} style={{ fontWeight: 300, opacity: .87, fontSize: window.innerWidth < 1200 ? '1.25em' : '1.5em', margin: '0px', marginLeft: '8px' }}> {Contacts['navigator.family.contactphone1']}</a>
                    ;
                break;

            case 'navigator.toolbar.socialMedia':
                mobileItem =

                    <Row style={{ zIndex: 1, textAlign: 'right', verticalAlign: 'top' }}>
                        <div style={{ fontWeight: 300, opacity: .87, verticalAlign: 'top', fontSize: window.innerWidth < 1200 ? '1.4em' : '2em', paddingTop: window.innerWidth < 1200 ? '4px' : '0px', marginTop: '16px', display: 'inline-block' }}>{t('toolbar.socialmedia')} </div>


                        <a href={"https://www.facebook.com/langearlyyears/"} target="_blank" rel="noopener noreferrer" style={{ color: faceBookColor, fontWeight: 300, opacity: .87, fontSize: isTablet ? '1.25em' : '1.5em', paddingTop: '0px', zIndex: 99999, cursor: 'pointer' }} >
                            <FaceBookIcon style={{ fontSize: '2em', marginLeft: '8px', marginTop: '16px' }} />
                        </a>
                        <a href={"https://www.instagram.com/langleyearlyyears/"} target="_blank" rel="noopener noreferrer" style={{ color: 'black', fontWeight: 300, opacity: .87, fontSize: isTablet ? '1.25em' : '1.5em', paddingTop: '0px', zIndex: 99999, cursor: 'pointer' }} >
                            <InstagramIcon style={{ fontSize: '2em', marginLeft: '8px', marginTop: '16px' }} />
                        </a>


                    </Row>;

                break;

            case 'navigator.toolbar.instagram':
                mobileItem =

                    <a href={"https://www.instagram.com/langleyearlyyears/"} target="_blank" rel="noopener noreferrer" style={{ color: 'black', fontWeight: 300, opacity: .87, fontSize: '1.5em', margin: '0px', paddingTop: '0px' }} >
                        <InstagramIcon style={{ fontSize: '1.5em', paddingTop: '8px' }} />
                    </a>;

                break;

            case 'navigator.family.summary':
                mobileItem =

                    <div className='text-box bg-lightorange'  >
                        <div className='title' > {t('navigator.family.contactname1')}</div>
                        <br></br>
                        <a href={'mailto:' + Contacts['navigator.family.contactemail1']} >
                            <div className='email-link text-orange' >   <EmailIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} />{Contacts['navigator.family.contactemail1']}</div></a>
                        <a href={'tel:' + Contacts['navigator.family.contactphone1']} className='phone-link text-orange' >  <PhoneIcon alt={t('button.click.email')} style={{ padding: '0px 4px 0px 0px', marginBottom: '-8px' }} /> {Contacts['navigator.family.contactphone1']}</a>
                    </div>;


                break;



            case 'aldergroveTitle':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBanner})` }} >
                            {t('aldergrove.programs')}
                        </div>
                    </div>
                break;
            case 'aldergrove.dropin':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-purple' >  {t('aldergrove.title')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightblue' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('aldergrove.dropin.title')}</div>
                                {t('aldergrove.dropin.p1')}
                                <a href={"https://www.encompass-supports.com/family-place-resource-centre.html"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.dropin.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }


                    </React.Fragment>
                break;

            case 'aldergrove.bestbabies':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-purple' >  {t('aldergrove.title')} </div> : null}

                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightblue'>
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('aldergrove.bestbabies.title')}</div>
                                {tabName === 'hubs' || isMobileOnly === false ?
                                    <React.Fragment>
                                        <div className='subtitle' > {t('aldergrove.bestbabies.subtitle1')}
                                            <div className='subtitle-item'>{t('aldergrove.bestbabies.p1')}</div>
                                        </div>
                                        <div className='subtitle' > {t('aldergrove.bestbabies.subtitle2')}
                                            <div className='subtitle-item'>{t('aldergrove.bestbabies.p2')}</div>
                                        </div>



                                    </React.Fragment>
                                    : null}
                                <a href={"https://www.encompass-supports.com/best-babies-of-the-langleys.html"} target="_blank" rel="noopener noreferrer" >

                                    <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.bestbabies.link']}</div></a>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />}


                    </React.Fragment>;
                break;

            case 'aldergrove.ywca':
                mobileItem = <React.Fragment>
                    {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-purple' >  {t('aldergrove.title')} </div> : null}
                    {tabName === 'hubs' || isMobileOnly === false ?
                        <div className='text-box bg-lightblue' >
                            <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                            <div className='title' > {t('aldergrove.ywca.title')}</div>
                            {t('aldergrove.ywca.p1')}
                            <a href={"https://ywcavan.org/programs/single-mothers-support-services/group-support"} target="_blank" rel="noopener noreferrer" >
                                <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.ywca.link']}</div></a>
                        </div>
                        :
                        <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />}
                </React.Fragment>;
                break;

            case 'aldergrove.immunization':
                mobileItem = <React.Fragment>
                    {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-purple' >  {t('aldergrove.title')}  </div> : null}
                    {tabName === 'hubs' || isMobileOnly === false ?


                        <div className='text-box bg-lightblue' >
                            <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                            <div className='title' > {t('aldergrove.immunization.title')}</div>
                            {t('aldergrove.immunization.p1')}
                            <a href={"https://www.fraserhealth.ca/Service-Directory/Services/Public-Health-Services/public-health-unit#.XiCm5chKiUk"} target="_blank" rel="noopener noreferrer" >
                                <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.immunization.link']}</div></a>
                        </div>
                        :
                        <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                    }
                </React.Fragment >;
                break;

            case 'aldergrove.outreach':
                mobileItem = <React.Fragment>
                    {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-purple' >  {t('aldergrove.title')}  </div> : null}
                    {tabName === 'hubs' || isMobileOnly === false ?

                        <div className='text-box bg-lightblue' >
                            <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                            <div className='title' > {t('aldergrove.outreach.title')}</div>
                            {t('aldergrove.outreach.p1')}
                            <a href={"https://www.encompass-supports.com/parent-support-outreach.html"} target="_blank" rel="noopener noreferrer" >
                                <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.outreach.link']}</div></a>

                        </div>

                        :
                        <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                    }
                </React.Fragment >;

                break;

            case 'aldergrove.playgroup':
                mobileItem = <React.Fragment>
                    {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-purple' >  {t('aldergrove.title')} </div> : null}
                    {tabName === 'hubs' || isMobileOnly === false ?
                        <div className='text-box bg-lightblue' >
                            <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />   <div className='title' > {t('aldergrove.playgroup.title')}</div>
                            {t('aldergrove.playgroup.p1')}
                            <a href={"https://www.lfvas.org/children-families"} target="_blank" rel="noopener noreferrer" >
                                <div className='link text-purple' >   <img src={AssetTextBoxArrow} alt={t('button.click.link')} />{Contacts['aldergrove.playgroup.link']}</div></a>

                        </div>
                        :
                        <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                    }
                </React.Fragment >;

                break;

            case 'boyBalloon':
                mobileItem =
                    <img src={AssetBoyBalloon} alt={t(description.toString())} className='vert-move' style={{ width: '100%', maxWidth: '350px' }} />;

                break;

            case 'aldergrove.address':
                mobileItem =
                    <div className='address-box-background' style={{ backgroundImage: `url(${AssetBlueBoard})` }}>
                        <p> {t('aldergrove.locationname')}</p>
                        {Contacts['aldergrove.locationaddress']}<br />
                        <a href='https://goo.gl/maps/yb9T3DJSzNA3zM7r8' target="_blank" rel="noopener noreferrer" style={{ fontWeight: 100 }} >
                            {t('view.map')}
                        </a><br />
                        {isMobileOnly ?
                            <a href={'tel:' + Contacts['aldergrove.locationphone']}>  <img src={AssetPhone} alt={t('button.click.link')} /> {Contacts['aldergrove.locationphone']}</a>
                            : Contacts['aldergrove.locationphone']
                        }

                        <p style={{ fontStyle: 'italic', fontSize: '.8em', fontWeight: 300 }}>  {t('aldergrove.locationnote')}</p>
                    </div>;
                break;


            case 'lcdouglasTitle':
                mobileItem =

                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPink})` }} >
                            {t('lcdouglas.programs')}
                        </div>
                    </div>
                    ;
                break;

            case 'lcdouglas.programs':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-pink' >  {t('lcdouglas.title')} <br />{t('lcdouglas.locationname')} </div> : null}

                        {tabName === 'hubs' || isMobileOnly === false ?
                            <React.Fragment>


                                <div className='text-box bg-lightgreen'>
                                    <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />

                                    <div className='title' > {t('lcdouglas.programs.title')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p1')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p2')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p3')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p4')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p5')}</div>
                                    <div className='bullet-item'>{t('lcdouglas.programs.p6')}</div>
                                    <a href={"https://www.langleycity.ca"} target="_blank" rel="noopener noreferrer" >
                                        <div className='link text-green' >   <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} />{Contacts['lcdouglas.programs.link']}</div></a>
                                </div>
                            </React.Fragment>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />}
                    </React.Fragment>;
                break;

            case 'lcdouglas.bestbabies':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-pink' >  {t('lcdouglas.title')}<br /> {t('lcdouglas.locationname')} </div> : null}

                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightgreen'>
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />

                                <div className='title' > {t('lcdouglas.bestbabies.title')}</div>
                                {tabName === 'hubs' || isMobileOnly === false ?
                                    <React.Fragment>
                                        <div className='subtitle' > {t('lcdouglas.bestbabies.subtitle1')}
                                            <div className='subtitle-item'>{t('lcdouglas.bestbabies.p1')}</div>
                                        </div>
                                        <div className='subtitle' > {t('lcdouglas.bestbabies.subtitle2')}
                                            <div className='subtitle-item'>{t('lcdouglas.bestbabies.p2')}</div>
                                        </div></React.Fragment>
                                    : null}
                                <a href={"https://www.encompass-supports.com/best-babies-of-the-langleys.html"} target="_blank" rel="noopener noreferrer" >

                                    <div className='link text-green' >   <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} />{Contacts['lcdouglas.bestbabies.link']}</div></a>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />}


                    </React.Fragment>;
                break;


            case 'lcdouglas.strongstart':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-pink' >  {t('lcdouglas.title')} <br />{t('lcdouglas.locationname')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightgreen' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />

                                <div className='title' > {t('lcdouglas.strongstart.title')}</div>
                                {t('lcdouglas.strongstart.p1')}<br></br><br></br>
                                {t('lcdouglas.strongstart.p2')}

                                <a href={"https://www.sd35.bc.ca/programs-initiatives/early-learning-birth-5-yrs/strongstart/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-green' >   <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} />{Contacts['lcdouglas.strongstart.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }


                    </React.Fragment>
                break;

          
            case 'lcdouglas.address':
                mobileItem =
                    <div className='lc-address-box-background' style={{ backgroundImage: `url(${AssetPinkBoard})` }}>
                        <p> {t('lcdouglas.locationname')}</p>
                        {Contacts['lcdouglas.locationaddress']}
                        <br />
                        <a href='https://goo.gl/maps/cjNZup3aheikW7Sh9' target="_blank" rel="noopener noreferrer" style={{ fontWeight: 100 }} >
                            {t('view.map')}
                        </a><br />
                        {isMobileOnly ?
                            <a href={'tel:' + Contacts['lcdouglas.locationphone']} className='text-green' >  <img src={AssetPhone} alt={t('button.click.link')} /> {Contacts['lcdouglas.locationphone']}</a>
                            : Contacts['lcdouglas.locationphone']
                        }
                    </div>;
                break;

            case 'girl5':
                mobileItem =
                    <img src={AssetGirl5} alt={t('image.girl.5')} className='side-move ' style={{ width: '100%', maxWidth: '350px' }} />;
                break;


            case 'lccommunityTitle':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerOrange})` }} >
                            {t('lccommunity.programs')}
                        </div>
                    </div>;
                break;

            case 'lccommunity.familyplace':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')} <br />{t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.familyplace.title')}</div>
                                {t('lccommunity.familyplace.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/childfamily-services/family-place-resource-programs/family-place-south/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.familyplace.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'lccommunity.multicultural':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')} <br />{t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.multicultural.title')}</div>
                                {t('lccommunity.multicultural.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/childfamily-services/multicultural-family-navigators/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.multicultural.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'lccommunity.parenting':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')}<br /> {t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.parenting.title')}</div>
                                {t('lccommunity.parenting.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/family-counselling/parent-outreach/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.parenting.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'lccommunity.settlement':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')}<br /> {t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.settlement.title')}</div>
                                {t('lccommunity.settlement.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/settlement-integration-services/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.settlement.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;


            case 'lccommunity.workshops':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')} <br />{t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.workshops.title')}</div>
                                {t('lccommunity.workshops.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/family-counselling/parent-education/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.workshops.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;
            case 'lccommunity.poverty':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-orange1' >  {t('lccommunity.title')} <br />{t('lccommunity.subtitle')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightorange' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('lccommunity.poverty.title')}</div>
                                {t('lccommunity.poverty.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/povertylaw/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-orange' >   <img src={AssetTextBoxArrowOrange} alt={t('button.click.link')} />{Contacts['lccommunity.poverty.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'lccommunity.address':
                mobileItem =
                    <div className='lcc-address-box-background' style={{ backgroundImage: `url(${AssetOrangeBoard})` }} >
                        <p> {t('lccommunity.locationname')}</p>

                        {Contacts['lccommunity.locationaddress']}
                        <br />
                        <a href='https://goo.gl/maps/aYT3wazY7JRhVTQe6' target="_blank" rel="noopener noreferrer" style={{ fontWeight: 100 }} >
                            {t('view.map')}
                        </a><br />
                        {isMobileOnly ?
                            <a href={'tel:' + Contacts['lccommunity.locationphone']} className='text-orange' >  <img src={AssetPhone} alt={t('button.click.link')} /> {Contacts['lccommunity.locationphone']}</a>
                            : Contacts['lccommunity.locationphone']}

                    </div>;
                break;

            case 'boy1':
                mobileItem =
                    <img src={AssetBoy1} alt={t('image.boy.1')} className='side-move' style={{ width: '100%', maxWidth: '350px' }} />
                    ;
                break;

            case 'willoughbyTitle':
                mobileItem =
                    <div style={{ display: 'inline-block' }}>
                        <div className='banner-box-background heading-box' style={{ backgroundImage: `url(${AssetBannerPurple})` }} >
                            {t('willoughby.programs')}
                        </div>
                    </div>;
                break;

            case 'willoughby.babytalk':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.babytalk.title')}</div>
                                {t('willoughby.babytalk.p1')}
                                <a href={"https://www.facebook.com/langearlyyears/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.babytalk.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;
      
            case 'willoughby.creativeart':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}  </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.creativeart.title')}</div>
                                {t('willoughby.creativeart.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/settlement-integration-services/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.creativeart.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'willoughby.familyplace':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.familyplace.title')}</div>
                                {t('willoughby.familyplace.p1')}
                                <a href={"https://www.lcss.ca/programs-and-services/childfamily-services/family-place-resource-programs/family-place-north/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.familyplace.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'willoughby.infant':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}  </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.infant.title')}</div>
                                {t('willoughby.infant.p1')}
                                <a href={"https://llaurie3.wixsite.com/website"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.infant.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'willoughby.playgroup':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}  </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.playgroup.title')}</div>
                                {t('willoughby.playgroup.p1')}
                                <a href={"https://www.lfvas.org/children-families"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.playgroup.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

          

                // mobileItem =
                //     <React.Fragment>
                //         {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}  </div> : null}
                //         {tabName === 'hubs' || isMobileOnly === false ?
                //             <div className='text-box bg-lightpink' >
                //                 <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                //                 <div className='title' > {t('family.postpartum.title')}</div>
                //                 {t('family.postpartum.p1')}
                //                 <a href={"http://postpartum.org/"} target="_blank" rel="noopener noreferrer" >
                //                     <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['family.postpartum.link']}</div></a>
                //             </div>
                //             :
                //             <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                //         }
                //     </React.Fragment>
                // break;

            case 'willoughby.spanish':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')} </div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.spanish.title')}</div>
                                {t('willoughby.spanish.p1')}
                                <a href={"https://www.encompass-supports.com/spanish-family-programming.html"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.spanish.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'willoughby.strongstart':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}</div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.strongstart.title')}</div>
                                {t('willoughby.strongstart.p1')}<br></br><br></br>
                                {t('willoughby.strongstart.p2')}

                                <a href={"https://www.sd35.bc.ca/programs-initiatives/early-learning-birth-5-yrs/strongstart/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.strongstart.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'willoughby.ywca':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-coral' >  {t('willoughby.title')}</div> : null}
                        {tabName === 'hubs' || isMobileOnly === false ?
                            <div className='text-box bg-lightpink' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='title' > {t('willoughby.ywca.title')}</div>
                                {t('willoughby.ywca.p1')}<br></br>

                                <a href={"https://ywcavan.org/programs/support-single-moms"} target="_blank" rel="noopener noreferrer" >
                                    <div className='link text-pink' >   <img src={AssetTextBoxArrowPink} alt={t('button.click.link')} />{Contacts['willoughby.ywca.link']}</div></a>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;



            case 'willoughby.address':
                mobileItem =
                    <div className='w-address-box-background' style={{ backgroundImage: `url(${AssetPurpleBoard})`, marginBottom: '40px' }} >
                        <p> {t('willoughby.locationname')}</p>
                        {Contacts['willoughby.locationaddress']}  <br />
                        <a href='https://goo.gl/maps/iuSgTwd5sBUWKeSR8' target="_blank" rel="noopener noreferrer" style={{ fontWeight: 100 }} >
                            {t('view.map')}
                        </a><br />
                        {isMobileOnly ?
                            <a href={'tel:' + Contacts['willoughby.locationphone']} className='text-pink' >  <img src={AssetPhone} alt={t('button.click.link')} /> {Contacts['willoughby.locationphone']}</a>
                            : Contacts['willoughby.locationphone']}

                        <p style={{ fontStyle: 'italic', fontSize: '.8em', fontWeight: 300 }}>  {t('willoughby.locationnote')}</p>

                    </div>;
                break;

            case 'girl6':
                mobileItem =
                    <img src={AssetGirl6} alt={t('image.girl.6')} className='vert-move' style={{ width: '100%', maxWidth: '350px' }} />
                    ;
                break;





            case 'supportTitle':
                mobileItem =
                    <React.Fragment>
                        <div className='title text-orange2'>{t('support.title')}</div>
                        <div className='big-title text-orange2'>{t('support.subtitle')}</div>
                        <div className='support-box-background ' style={{ backgroundImage: `url(${AssetBannerBlueMobile})` }} >
                            <p>{t('support.p1')}</p>
                        </div>

                    </React.Fragment>;
                break;

            case 'supportTitle1':
                mobileItem =
                    <React.Fragment>
                        {/* <div className='title text-darkblue'>{t('support.title')}</div> */}
                        <div className='big-title text-darkblue'>{t('support.title')} {t('support.subtitle')}</div>


                    </React.Fragment>;
                break;

            case 'supportTitle2':
                mobileItem =
                    <React.Fragment>
                        <div className='support-box-background ' style={{ backgroundImage: `url(${AssetBannerBlueMobile})` }} >
                            <p>{t('support.p1')}</p>
                        </div>

                    </React.Fragment>;
                break;

            case 'childcare.resource':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading bg-pink' >  {t('support.childcare.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('childcare.resource.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-subtitle' > {t('childcare.resource.subtitle')}</div>
                                <div className='support-address' > {Contacts['childcare.resource.address']}</div>
                                <a href={'tel:' + Contacts['childcare.resource.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['childcare.resource.contact']}</div></a>
                                <a href={"https://www.lcss.ca/programs-and-services/childfamily-services/child-care-resource-referral/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['childcare.resource.link1']}</div></a>
                                <p>{t('childcare.resource.p1')}</p>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'earlyintervention.familysmart':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink'  >  {t('support.earlyintervention.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('earlyintervention.familysmart.title')}</div>
                                <hr className='support-line'></hr>
                                <a href={'tel:' + Contacts['earlyintervention.familysmart.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['earlyintervention.familysmart.contact']}</div></a>
                                <a href={"https://familysmart.ca"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['earlyintervention.familysmart.link']}</div></a>

                                <div className='bullet-item' >{t('earlyintervention.familysmart.point1')}</div>

                                <div className='bullet-item' >{t('earlyintervention.familysmart.point2')}</div>
                                <div className='bullet-item-text' >{t('earlyintervention.familysmart.point2.p1')}</div>

                                <div className='bullet-item' >{t('earlyintervention.familysmart.point3')}</div>
                                <div className='bullet-item-text' >{t('earlyintervention.familysmart.point3.p1')}</div>


                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'earlyintervention.familysupport':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.earlyintervention.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('earlyintervention.familysupport.title')}</div>
                                <hr className='support-line'></hr>
                                <a href={'tel:' + Contacts['earlyintervention.familysupport.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['earlyintervention.familysupport.contact']}</div></a>
                                <a href={"https://familysupportbc.com"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['earlyintervention.familysupport.link']}</div></a>
                                <div className='bullet-item' >{t('earlyintervention.familysupport.point1')}</div>
                                <div className='bullet-item' >{t('earlyintervention.familysupport.point2')}</div>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'earlyintervention.childdevelopment':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.earlyintervention.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('earlyintervention.childdevelopment.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['earlyintervention.childdevelopment.address']}</div>

                                <a href={'tel:' + Contacts['earlyintervention.childdevelopment.contact']} > <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['earlyintervention.childdevelopment.contact']}</div></a>
                                <a href={"https://inclusionlangley.com/services-for-children/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['earlyintervention.childdevelopment.link']}</div></a>
                                <div className='bullet-item' >{t('earlyintervention.childdevelopment.point1')}</div>
                                <div className='bullet-item' >{t('earlyintervention.childdevelopment.point2')}</div>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.childdevelopment':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.childdevelopment.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.childdevelopment.address']}</div>

                                <a href={'tel:' + Contacts['family.childdevelopment.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.childdevelopment.contact']}</div></a>
                                <div className='bullet-item' >{t('family.childdevelopment.point1')}</div>
                                <div className='bullet-item' >{t('family.childdevelopment.point2')}</div>
                                <div className='bullet-item-text' >{t('family.childdevelopment.point2.p1')}</div>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.encompass':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.encompass.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.encompass.address']}</div>
                                <a href={'tel:' + Contacts['family.encompass.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.encompass.contact']}</div></a>
                                <a href={"https://encompass-supports.com"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.encompass.link']}</div></a>

                                <div className='bullet-item' >{t('family.encompass.point1')}</div>
                                <div className='bullet-item' >{t('family.encompass.point2')}</div>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.library':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.library.title')}</div>
                                <hr className='support-line'></hr>
                                <a href={'tel:' + Contacts['family.library.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.library.contact']}</div></a>
                                <a href={"https://fvrl.bc.ca"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.library.link']}</div></a>


                                <div className='bullet-item' >{t('family.library.point1')}</div>
                                <div className='bullet-item' >{t('family.library.point2')}</div>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.immigrant':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.immigrant.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.immigrant.address']}</div>
                                <a href={'tel:' + Contacts['family.immigrant.contact']} >
                                    <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.immigrant.contact']}</div></a>
                                <a href={"https://issbc.org"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.immigrant.link']}</div></a>

                                <p>{t('family.immigrant.p1')}</p>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.laleche':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.laleche.title')}</div>
                                <hr className='support-line'></hr>
                                <a href={"https://www.lllc.ca/lllc-langley"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.laleche.link']}</div></a>

                                <p>{t('family.laleche.p1')}</p>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.community':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.community.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.community.address']}</div>
                                <a href={'tel:' + Contacts['family.community.contact']} > <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.community.contact']}</div></a>
                                <a href={"https://www.lcss.ca/"} target="_blank" rel="noopener noreferrer" > <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.community.link']}</div></a>
                                <div className='bullet-item' >{t('family.community.point1')}</div>
                                <div className='bullet-item' >{t('family.community.point2')}</div>
                                <div className='bullet-item' >{t('family.community.point3')}</div>
                                <div className='bullet-item' >{t('family.community.point4')}</div>
                                <div className='bullet-item' >{t('family.community.point5')}</div>


                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.SD35':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.SD35.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.SD35.address']}</div>
                                <a href={'tel:' + Contacts['family.SD35.contact']} > <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.SD35.contact']}</div></a>
                                <a href={"https://www.sd35.bc.ca/programs-initiatives/early-learning-birth-5-yrs/"} target="_blank" rel="noopener noreferrer" > <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.SD35.link']}</div></a>
                                <div className='bullet-item' >{t('family.SD35.point1')}</div>
                                <div className='bullet-item' >{t('family.SD35.point2')}</div>
                                <div className='bullet-item' >{t('family.SD35.point3')}</div>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.aboriginal':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.aboriginal.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.aboriginal.address']}</div>
                                <a href={'tel:' + Contacts['family.aboriginal.contact']} > <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.aboriginal.contact']}</div></a>
                                <a href={"https://lfvas.org"} target="_blank" rel="noopener noreferrer" > <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.aboriginal.link']}</div></a>


                                <div className='bullet-item' >{t('family.aboriginal.point1')}</div>
                                <div className='bullet-item' >{t('family.aboriginal.point2')}</div>
                                <div className='bullet-item' >{t('family.aboriginal.point3')}</div>
                                <div className='bullet-item' >{t('family.aboriginal.point4')}</div>
                                <div className='bullet-item-text' >{t('family.aboriginal.point4.p1')}</div>
                                <div className='bullet-item' >{t('family.aboriginal.point5')}</div>
                                <div className='bullet-item' >{t('family.aboriginal.point6')}</div>


                             
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'family.metis':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.metis.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['family.metis.address']}</div>
                                <a href={'tel:' + Contacts['family.metis.contact']} > <div className='text-green'>  <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['family.metis.contact']}</div></a>
                                <a href={"https://www.mnbc.ca/work-programs/ministries/children-and-families"} target="_blank" rel="noopener noreferrer" > <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.metis.link']}</div></a>

                                <p>{t('family.metis.p1')}</p>
                                <div className='bullet-item' >{t('family.metis.point1')}</div>
                                <div className='bullet-item' >{t('family.metis.point2')}</div>
                                <div className='bullet-item' >{t('family.metis.point3')}</div>
                                <div className='bullet-item' >{t('family.metis.point4')}</div> 
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;


            case 'family.postpartum':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.family.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('family.postpartum.title')}</div>
                                <hr className='support-line'></hr>
                                <a href={"http://postpartum.org/"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['family.postpartum.link']}</div></a>

                                <p>{t('family.postpartum.p1')}</p>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'health.foodbanklangley':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.health.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('health.foodbanklangley.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['health.foodbanklangley.address']}</div>
                                <a href={'tel:' + Contacts['health.foodbanklangley.contact']}> <div className='text-green'>
                                    <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['health.foodbanklangley.contact']}</div></a>
                                <a href={"https://langleyfoodbank.com"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['health.foodbanklangley.link']}</div></a>


                                <div className='bullet-item' >{t('health.foodbanklangley.point1')}</div>
                                <div className='bullet-item-text-bold' >{t('health.foodbanklangley.point1.p1')}</div>
                                <div className='bullet-item-text' >{t('health.foodbanklangley.point1.p1.hours')}</div>

                                <div className='bullet-item' >{t('health.foodbanklangley.point2')}</div>

                                <p style={{ fontStyle: 'italic' }}>{t('health.foodbanklangley.p1')}</p>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'health.foodbankaldergrove':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.health.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('health.foodbankaldergrove.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['health.foodbankaldergrove.address']}</div>
                                <a href={'tel:' + Contacts['health.foodbankaldergrove.contact']}> <div className='text-green'>
                                    <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['health.foodbankaldergrove.contact']}</div></a>
                                <a href={"https://langleyfoodbank.com/aldergrove"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['health.foodbankaldergrove.link']}</div></a>


                                <div className='bullet-item' >{t('health.foodbankaldergrove.point1')}</div>
                                <div className='bullet-item-text-bold' >{t('health.foodbankaldergrove.point1.p1')}</div>
                                <div className='bullet-item-text' >{t('health.foodbankaldergrove.point1.p1.hours')}</div>


                                <p style={{ fontStyle: 'italic' }}>{t('health.foodbankaldergrove.p1')}</p>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'health.publichealth':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.health.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('health.publichealth.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['health.publichealth.address']}</div>
                                <a href={"tel:" + Contacts['health.publichealth.contact']} > <div className='text-green'>
                                    <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['health.publichealth.contact']}</div></a>
                                <a href={"https://fraserhealth.ca"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['health.publichealth.link']}</div></a>


                                <div className='bullet-item' >{t('health.publichealth.point1')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point2')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point3')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point4')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point5')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point6')}</div>
                                <div className='bullet-item' >{t('health.publichealth.point7')}</div>
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'health.foodbanksources':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.health.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('health.foodbanksources.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['health.foodbanksources.address']}</div>
                                <a href="tel:604-531-8168" > <div className='text-green'>
                                    <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['health.foodbanksources.contact']}</div></a>
                                <a href={"https://sourcesbc.ca"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['health.foodbanksources.link']}</div></a>


                                <div className='bullet-item' >{t('health.foodbanksources.point1')}</div>
                                <div className='bullet-item-text-bold' >{t('health.foodbanksources.point1.p1')}</div>
                                <div className='bullet-item-text' >{t('health.foodbanksources.point1.p1.hours')}</div>
                                <div className='bullet-item-text-bold' >{t('health.foodbanksources.point1.p2')}</div>
                                <div className='bullet-item-text' >{t('health.foodbanksources.point1.p2.hours')}</div>
                                <div className='bullet-item-text-bold' >{t('health.foodbanksources.point1.p3')}</div>
                                <div className='bullet-item-text' >{t('health.foodbanksources.point1.p3.hours')}</div>

                                <div className='bullet-item' >{t('health.foodbanksources.point2')}</div>
                                <div className='bullet-item-text' >{t('health.foodbanksources.point2.p1')}</div>

                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;

            case 'health.raphael':
                mobileItem =
                    <React.Fragment>
                        {tabName === 'search' || tabName === 'favs' ? <div className='search-heading  bg-pink' >  {t('support.health.title')}</div> : null}
                        {tabName === 'support' || isMobileOnly === false ?
                            <div className='support-box' >
                                <Favourite showFav={showFav} favStatus={favStatus} itemName={itemName} />
                                <div className='support-title' > {t('health.raphael.title')}</div>
                                <hr className='support-line'></hr>
                                <div className='support-address' > {Contacts['health.raphael.address']}</div>
                                <a href={'tel:' + Contacts['health.raphael.contact']}> <div className='text-green'>
                                    <img src={AssetPhoneGreen} alt={t('button.click.link')} style={{ width: '32px', padding: '2px' }} />{Contacts['health.raphael.contact']}</div></a>
                                <a href={"https://raphaelhouse.ca"} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <img src={AssetTextBoxArrowGreen} alt={t('button.click.link')} style={{ width: '32px' }} />{Contacts['health.raphael.link']}</div></a>
                                <a href={"mailto:" + Contacts['health.raphael.email']} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-green'  > <EmailIcon style={{ width: '32px' }} />{Contacts['health.raphael.email']}</div></a>
                                <div className='bullet-item' >{t('health.raphael.point1')}</div>
                                <div className='bullet-item-text-bold' >{t('health.raphael.point1.p1')}</div>
                                <div className='bullet-item-text' >{t('health.raphael.point1.p1.hours')}</div>
                      
                            </div>
                            :
                            <ItemTitle itemName={itemName} favStatus={favStatus} tabName={tabName} />
                        }
                    </React.Fragment>
                break;


            case 'girl2':
                mobileItem =
                    <img src={AssetGirl2} alt={t(description.toString())} className='vert-move' style={{ width: '100%', maxWidth: '400px' }} />;
                break;
            case 'boy2':
                mobileItem =
                    <img src={AssetBoy2} alt={t(description.toString())} className='vert-move' style={{ width: '100%', maxWidth: '350px' }} />;
                break;


            case 'acknowledge.firstnations':
                mobileItem =
                    <React.Fragment>
                        {tabName !== 'search' && tabName !== 'favs' ?
                            <div className="acknowledge-box" >
                                {t('acknowledge.firstnations')}
                            </div> : null}
                    </React.Fragment>;

                break;




            default:
                mobileItem = <div style={{ textAlign: 'center', paddingTop: '40px', lang: i18n.language }}>
                    {itemName}
                </div>;

        }

        return (mobileItem)
    }

};

const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        favList: state.menuSelection.favList,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey)),
        onMobileMenuClick: (mobileMenu) => dispatch(actions.setMobileMenu(mobileMenu)),
        onMobileToolbarClick: (mobileMenuIndex) => dispatch(actions.setMobileToolbar(mobileMenuIndex)),
        setFav: (itemName, status) => dispatch(actions.setFavourite(itemName, status)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Item));
