import produce from 'immer';

export const LOCALES_GET = 'LOCALES_GET';
export const TRANSLATIONS_GET = 'TRANSLATIONS_GET';

const initialState = {
  locales: [],
  translations: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case `${LOCALES_GET}_SUCCESS`:
        draft.locales = action.payload.data;
        break;
      case `${TRANSLATIONS_GET}_SUCCESS`:
        draft.translations = action.payload.data;
        break;
      default:
        break;
    }
  });
