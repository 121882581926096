import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MobileTopBar from '../../components/Navigation/MobileTopBar';
import * as actions from '../../actions/menuSelection';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import HubsIcon from '@material-ui/icons/LocationOn';
import SupportIcon from '@material-ui/icons/Help';
import FavsIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';

class MobileLayout extends Component {

    state = {
        currentIndex: parseInt(this.props.mobileMenuIndex),
        windowHeight: window.innerHeight,
    };

    componentDidMount() {
        this.setState({ currentIndex: parseInt(this.props.mobileMenuIndex), });
        this.setState({ windowHeight: window.innerHeight, });
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { currentIndex } = this.state;
        const mobileMenuIndex = this.props.mobileMenuIndex;

        if (parseInt(mobileMenuIndex) !== currentIndex && mobileMenuIndex !== undefined && mobileMenuIndex !== null)
            this.setState({ currentIndex: parseInt(mobileMenuIndex), });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ windowHeight: window.innerHeight, });
    };

    render() {

        const { pageName, pageTitle, returnHome, t, i18n, mainMenuKey, onMobileToolbarClick, onMenuClick } = this.props;
        const { windowHeight } = this.state;

        const handleClick = (e, newMenu) => {
            onMobileToolbarClick(newMenu);
            this.setState({ currentIndex: parseInt(newMenu), });
        };

        let bottomToolbar = (mainMenuKey === "home" || mainMenuKey === "hubs" || mainMenuKey === "support" || mainMenuKey === "favs" || mainMenuKey === "search") ?
            <React.Fragment>

                <hr className='line'></hr>

                <BottomNavigation
                    value={this.state.currentIndex}
                    onChange={(event, newValue) => {
                        handleClick(event, newValue);
                    }}
                    showLabels
                    className='navigation-bottom-bar'
                >
                    <BottomNavigationAction label={t('menu.home')} icon={<HomeIcon />} />
                    <BottomNavigationAction label={t('menu.hubs')} icon={<HubsIcon />} />
                    <BottomNavigationAction label={t('menu.support')} icon={<SupportIcon />} />
                    <BottomNavigationAction label={t('menu.favs')} icon={<FavsIcon />} />
                    <BottomNavigationAction label={t('menu.search')} icon={<SearchIcon />} />
                </BottomNavigation>

            </React.Fragment>

            :
            null



        return (

            <div className='mobileLayout' style={{ height: windowHeight }}>
                <div className='mobileHeaderStyle'>
                    <MobileTopBar
                        pageName={pageName}
                        pageTitle={pageTitle}
                        onBack={returnHome}
                        t={t}
                        i18n={i18n}
                        onMenuClick={onMenuClick}
                    />
                </div>
                <div className='mobileContent'  >
                    {this.props.children}
                </div>
                {bottomToolbar}

            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMobileMenuClick: (mobileMenu) => dispatch(actions.setMobileMenu(mobileMenu)),
        onMobileToolbarClick: (mobileMenuIndex) => dispatch(actions.setMobileToolbar(mobileMenuIndex)),
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(MobileLayout));