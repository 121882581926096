import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components//Navigation/Toolbar';
import * as actions from '../../actions/menuSelection'
import { withTranslation } from 'react-i18next';
import backgroundImageBrowser from '../../images/background.png';

class AppLayout extends Component {

    render() {

        const { t, i18n } = this.props;

        return (
            <div >
                <Toolbar
                    drawerToggleClicked={this.sideDrawerToggleHandler}
                    mainMenuSelection={this.props.mainMenuSelection}
                    mainMenuKey={this.props.mainMenuKey}
                    onMainMenuClick={this.props.onMainMenuClick}
                    search={this.props.setSearch}
                    searchString={this.props.searchString}
                    t={t}
                    i18n={i18n}
                />
                <div className='content' style={{ backgroundImage: `url(${backgroundImageBrowser})`, backgroundRepeat: 'repeat', backgroundPosition: 'top', height: '100%', width: '100%' }}>
                    {this.props.children}
                </div>
            </div >
        )
    };
}

const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        searchString: state.menuSelection.searchString
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {


    return {
        onMainMenuClick: (mainMenuSelection, mainMenuKey) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey)),
        setSearch: (searchString) => dispatch(actions.setSearch(searchString))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(AppLayout));