import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Remove';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuLogo from '../../images/Icon-Mobile-Menu.png';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const colorMenu = 'rgba(0, 100, 155, 1)';
const colorMainToolbarText = 'rgba(0, 100, 155, 1)';
const colorChildToolbarText = 'black';
const colorToolbarDefault = 'white';
const colorOrange2 = 'rgba(244,167,150,1)';
const colorPurple = 'rgba(201,149,189, 1)';
const colorGreen = 'rgba(147,205,200,1)';
const colorOrange1 = 'rgba(245,175,166,1)';
const colorCoral = 'rgba(255,184,193, 1)';
const colorPink = 'rgba(255,159,170, 1)';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 250,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: 'none',
        padding: theme.spacing(2, 4, 3),
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        color: colorMainToolbarText,
        margin: '0px',
        marginTop:'4px',
        paddingRight:'0px',
    },

    menuButtonChild: {
        color: colorChildToolbarText,
        margin: '0px',

    },
    logo: {
        margin: '0px', height: '40px', width: '40px',

    },
    title: {
        flexGrow: 1,
        marginLeft: '4px',  //was 16
        marginTop: '4px',
        color: colorMainToolbarText,
        fontSize: '1.7em',
        fontWeight: '200',
        textAlign: 'left',
        fontFamily: 'Freude',
    },

    titleChild: {
        flexGrow: 1,
        marginLeft: '8px',
        marginTop: '4px',
        color: colorChildToolbarText,
        fontSize: '20px',
        fontWeight: '200',
        textAlign: 'left',
        fontFamily: 'Freude',
    },

    toolbar: {
        backgroundColor: colorToolbarDefault,
        borderRadius: '0px',
        boxShadow: 'none',
        

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: 250,


    },
    listTitle: {
        flexGrow: 1,
        color: "white",
        fontSize: '18px',
        fontWeight: '200',
        textAlign: 'left',
        fontFamily: 'Freude',
        marginTop: '28px',
    },
    listHeader: {
        backgroundColor: colorMenu,
        display: 'flex',
        marginBottom:'0px',
    },

    listLogo: {
        margin: '0px', maxHeight: '80px', maxWidth: '80px', padding: '16px', textAlign: 'center', flexGrow: 1,
    },

    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    listCopyright:{
        fontFamily: 'roboto', 
        fontWeight: 100, 
        fontSize: '1em',
        opacity:.7,

    },
    listDesignedBy: {
        fontFamily: 'roboto',
        fontWeight: 500,
        fontSize: '1em',
        opacity: .7,

    }

}));

export default function MobileTopBar(props) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
       
    });
    
    const { pageName, pageTitle,  t, i18n } = props;
    let colorToolbar = colorToolbarDefault;
    switch(pageName)
    {
        case 'aldergrove':
            colorToolbar = colorPurple;
            break;
        case 'lcdouglas':
            colorToolbar = colorGreen;
            break;
        case 'lccommunity':
            colorToolbar = colorOrange1;
            break;
        case 'willoughby':
            colorToolbar = colorCoral;
            break;
        case 'navigator':
            colorToolbar = colorOrange2;
            break;

        case 'childcare':
            colorToolbar = colorPink;
            break;
        case 'earlyIntervention':
            colorToolbar = colorPink;
            break;
        case 'family':
            colorToolbar = colorPink;
            break;
        case 'health':
            colorToolbar = colorPink;
            break;

        default:

    };
 
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [side]: open });
    };

    const handleMenuClick = (language) => {
        i18n.changeLanguage(language);
    };

    const handleAboutUsClick =() =>
    {
        props.onMenuClick(['aboutus'],'aboutus','top');
        
    };

    const handleInstallClick = () =>{
        props.onMenuClick(['install'], 'install', '');
    };
    
    const handleTermsClick = () => {
        props.onMenuClick(['terms'], 'terms', '');
    };
  
    const handleBack = () => {
        props.onMenuClick(['home'], 'home', 'top');
    };
    
    
    const sideList = side => {
        return (
            <div className={classes.list}
                role="presentation"
                onClick={toggleDrawer(side, false)}
                onKeyDown={toggleDrawer(side, false)}>

                <div className={classes.listHeader}>
                    <img src={MenuLogo} alt={t('menu.title')} className={classes.listLogo} />
                    <Typography className={classes.listTitle} variant="h6" >
                        {t('options')}
                    </Typography>
                </div>

                <List style={{marginTop:'0px',paddingTop:'0px'}} >
                    <ListItem  key="lang" style={{borderBottom:'thin solid black'}} >
                        <ListItemText primary={t('changeLanguage')} />
                    </ListItem>

                    <ListItem button key="english" onClick={() => handleMenuClick("en")}>
                         <ListItemIcon>{i18n.language === 'en' ?<CheckIcon />:<ClearIcon style={{color:'white'}}/>}</ListItemIcon> 
                        <ListItemText primary={t('en')} />
                    </ListItem>
                    <ListItem button key="spanish" onClick={() => handleMenuClick("es")}>
                        <ListItemIcon>{i18n.language === 'es' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                        <ListItemText primary={t('es')} />
                    </ListItem>
                    <ListItem button key="french" onClick={() => handleMenuClick("fr")}>
                        <ListItemIcon>{i18n.language === 'fr' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }}/>}</ListItemIcon> 
                        <ListItemText primary={t('fr')}/>
                    </ListItem>
                    <ListItem button key="korean" onClick={() => handleMenuClick("ko")}>
                        <ListItemIcon>{i18n.language === 'ko' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }}/>}</ListItemIcon> 
                        <ListItemText primary={t('ko')} />
                    </ListItem>
                    <ListItem button key="punjabi" onClick={() => handleMenuClick("pj")}>
                        <ListItemIcon>{i18n.language === 'pj' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }}/>}</ListItemIcon> 
                        <ListItemText primary={t('pj')} />
                    </ListItem>
                  
                    <ListItem key="aboutus" style={{ borderTop: 'thin solid black' }} onClick={() => handleAboutUsClick()} >
                        <ListItemText primary={t('home.aboutus.title')} />
                    </ListItem>
                    <ListItem key="info" style={{ borderTop: 'thin solid black' }} onClick={() => handleInstallClick()} >
                        <ListItemText primary={t('installAppMenu')} />
                    </ListItem>

                    <ListItem key="terms" style={{ borderTop: 'thin solid black' }} onClick={() => handleTermsClick()} >
                        <ListItemText primary={t('terms')} />
                    </ListItem>
       
                    <ListItem key="copyright" className={classes.listCopyright} style={{ borderTop: 'thin solid black' }} >
                        <ListItemText className={classes.listCopyright} primary={'© ' + (new Date().getFullYear()) + ' Inclusion Langley Society'} />
                     
                    </ListItem>
                    <ListItem key="designedBy" className={classes.listDesignedBy} style={{ borderTop: 'thin solid black' }} >
                        <ListItemText className={classes.listDesignedBy} primary={ 'Designed by Better World Software Inc.' } />
                     </ListItem>

                </List>

                <Divider />

            </div>
        )
    };

    const renderMenu = (
        <Drawer anchor="right"  open={state.right} onClose={toggleDrawer('right', false)}>
            {sideList('right')}
        </Drawer>
    );

    const renderToolbar = (

        <Toolbar className={classes.toolbar} style={{backgroundColor:colorToolbar}} >
            {pageName === "home" ?
                <React.Fragment>
          
                    <Typography className={classes.title} variant="h6" noWrap>
                        {t('menu.title')}
                    </Typography>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer('right', true)}
                    >
                        <SettingsIcon />
                    </IconButton>
                    {renderMenu}

                </React.Fragment>
                :
                <React.Fragment>
                    <IconButton
                        edge="start"
                        className={classes.menuButtonChild}
                        color="inherit"
                        aria-label="back"
                        onClick={() => handleBack()}
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography className={classes.titleChild} variant="h6" noWrap>
                        {pageTitle}
                    </Typography>
                </React.Fragment>
            }
        </Toolbar>

    );

    return (
        <React.Fragment>
            <AppBar position="static" elevation={0}>
                {renderToolbar}
               
            </AppBar>
            <hr className='line'></hr>
            
        </React.Fragment>
    );
}


