import React, { Component } from 'react';
import { connect } from 'react-redux';
import FavIconOn from '@material-ui/icons/Favorite';
import FavIconOff from '@material-ui/icons/FavoriteBorder';
import { Popconfirm } from 'antd';
import * as actions from '../../actions/menuSelection';
import { withTranslation } from 'react-i18next';


class Favourite extends Component {
    componentDidMount() {
       
    }
       
    setFavouriteItem = (itemName, status) => {
        const { setFav } = this.props;
        setFav(itemName, status);
    };



    render() {

        const { showFav, favStatus, itemName, t } = this.props;
        let favouriteIndicator = null;
        if(showFav)
        {
            if (favStatus)
                favouriteIndicator = <div className='fl-right' style={{cursor:'pointer'}}>
                    < Popconfirm
                        title={favStatus ? t('removeFav') : ""}
                        onConfirm={() => this.setFavouriteItem(itemName, !favStatus)
                        }
                        okText="Yes"
                        cancelText="No" >
                        <FavIconOn />
                    </Popconfirm >
                </div >;
            else
                favouriteIndicator =
                    <div className='fl-right' onClick={() => this.setFavouriteItem(itemName, !favStatus)}>
                        <FavIconOff />
                    </div> ;
        };
          
       return (
            <React.Fragment>
                {favouriteIndicator}
            </React.Fragment>
        )

    }

};

const mapStateToProps = state => {
    return {
        favList: state.menuSelection.favList,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
         setFav: (itemName, status) => dispatch(actions.setFavourite(itemName, status)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Favourite));
