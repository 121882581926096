import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../../locales/en.json';
import fr from '../../locales/fr.json';
import es from '../../locales/es.json';
import pj from '../../locales/pj.json';
import ko from '../../locales/ko.json';


i18n
     .use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            en: {
                translations:  en
               },
            fr: {
                translations:  fr
              },
            es: {
                translations: es
            },
            pj: {
                translations: pj
            },
            ko: {
                translations: ko
            },
        },
        fallbackLng: 'en',
        debug: false,
        whitelist:['en','fr','es','pj','ko'],
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;