import { createStore, applyMiddleware } from 'redux';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createPromise } from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from '../../reducers';

export const history = createHashHistory(); 

const configureStore = (preloadedState) =>

  (
    createStore(
      createRootReducer(history),
      preloadedState,
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        createPromise({ promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'] }),
        createLogger({
          collapsed: true,
        })
      )
    ));

export default configureStore;
