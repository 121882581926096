import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as actions from '../../actions/menuSelection';
import { Popover, Button } from 'antd';
import Item from './item'

import BookMarkIcon from '@material-ui/icons/Bookmark';
// import MoreIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';

const colorOrange2 = 'rgba(244,167,150,1)';
const colorPurple = 'rgba(201,149,189, 1)';
const colorGreen = 'rgba(147,205,200,1)';
const colorOrange1 = 'rgba(245,175,166,1)';
const colorCoral = 'rgba(255,184,193, 1)';
const colorPink = 'rgba(255,159,170, 1)';

class ItemTitle extends Component {

    state = { visible: false };


    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    gotoPage = pageName => {
        this.props.onMenuClick([pageName], pageName, '');
    };

    gotoPageSection = (pageName, sectionName) => {

        this.props.onMenuClick([pageName], pageName, sectionName);
    };


    render() {

        let { itemName, t } = this.props;

        let Link = null;
        let results = itemName.split('.', 2);
        let pageName = '';
        let title = null;
        let item = '';
        let currentTab = '';
        let titleBackground = 'rgba(0, 100, 155, 1)';
        let titleColor = 'black';
        let gotoPageText = '';

        if (results.length >= 1) {
            pageName = results[0];
            let sectionName = '';

        
            if (pageName === 'health' || pageName === 'family' || pageName === 'earlyintervention' || pageName === 'childcare') {
                pageName = 'support';
                currentTab = 'support';
                sectionName = results[0];
                gotoPageText = 'More support agencies...';
                title = pageName + '.' + sectionName + '.title';
                item = results[0] + '.' + results[1] + '.title';


            };

            switch (pageName) {
                case 'aldergrove':
                    titleBackground = colorPurple;
                    break;
                case 'lcdouglas':
                    titleBackground = colorGreen;
                    break;
                case 'lccommunity':
                    titleBackground = colorOrange1;
                    break;
                case 'willoughby':
                    titleBackground = colorCoral;
                    break;
                case 'navigator':
                    titleBackground = colorOrange2;
                    break;

                case 'support':
                    titleBackground = colorPink;
                    break;

                default:

            };

            if ( pageName === 'navigator') {
                currentTab = 'home';
                sectionName = results[0];
                gotoPageText = 'Learn more...';
                pageName = 'home';
                title = sectionName + '.title';
                item = results[0] + '.' + results[1] + '.title';
            };


            if (pageName === 'aldergrove' || pageName === 'lcdouglas' || pageName === 'lccommunity' || pageName === 'willoughby' ) {
              
                currentTab = 'hubs';
                sectionName = results[0];
                gotoPageText = 'More programs...';
                pageName = 'hubs';
                title = sectionName + '.title';
                item = results[0] + '.' + results[1] + '.title';

            };



            let content =
                <div style={{ margin: '0px -8px 48px -16px', padding: '0px' }}>
                    <Item itemName={itemName} tabName={currentTab} showFav={true} style={{ border: '2px dotted lightgrey' }} />
                    <Button type='link' className='fl-left' style={{ marginLeft: '8px', fontWeight: 300, color: 'rgba(0, 100, 155, 1)' }} onClick={() => this.gotoPageSection(pageName, sectionName)}>{gotoPageText}</Button>
                </div>;

            let Title = <div style={{ backgroundColor: titleBackground, margin: '-16px', padding: '12px 16px 12px 24px', color: titleColor, textAlign: 'left', fontSize: '1.25em' , border:'thin solid darkgrey'}}>
                {title ? t(title) : null}

                <div style={{ float: 'right', marginRight: '0px', paddingRight: '0px' }} onClick={this.hide}><CloseIcon/></div>


            </div>

            Link =
                <Popover
                    title={Title}
                    trigger="click"
                    visible={this.state.visible}
                    content={content}
                    onVisibleChange={this.handleVisibleChange}
                >
                    <div className='search-box'>
                        <div className='title'>{t(item)}</div>
                        <div className='bookmark'><BookMarkIcon style={{ color: titleBackground }} /></div>
                    </div>

                </Popover>;


        };

        return (Link)

    }

};


const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),
  
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ItemTitle));