import React, { useLayoutEffect, useState } from 'react';
import { Row, Col, Menu, Icon, Dropdown } from 'antd';
import girl1 from '../../images/Asset-Girl1.png';
import girl3 from '../../images/Asset-Girl3.png';
import boy2 from '../../images/Asset-Boy2.png';
import Item from '../../components/Items/item'
import Label from '../../elements/Label';
import LanguageIcon from '@material-ui/icons/Language';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Remove';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function Toolbar(props) {

    let language = localStorage.getItem('i18nextLng');
    const { t, i18n } = props;

    const handleClick = e => {
        props.onMainMenuClick(e.key, e.key, '');
    };

    const handleMenuClick = (language) => {
        i18n.changeLanguage(language);
    };

    const languageList = () => {
        return (

            <List style={{ marginTop: '0px', paddingTop: '0px', backgroundColor: 'white', border: 'thin solid lightgrey' }} >
                <ListItem key="lang" style={{ borderBottom: 'thin solid black' }} >
                    <ListItemText primary={t('changeLanguage')} />
                </ListItem>

                <ListItem button key="english" onClick={() => handleMenuClick("en")}>
                    <ListItemIcon>{i18n.language === 'en' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={t('en')} />
                </ListItem>
                <ListItem button key="spanish" onClick={() => handleMenuClick("es")}>
                    <ListItemIcon>{i18n.language === 'es' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={t('es')} />
                </ListItem>
                <ListItem button key="french" onClick={() => handleMenuClick("fr")}>
                    <ListItemIcon>{i18n.language === 'fr' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={t('fr')} />
                </ListItem>
                <ListItem button key="korean" onClick={() => handleMenuClick("ko")}>
                    <ListItemIcon>{i18n.language === 'ko' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={t('ko')} />
                </ListItem>
                <ListItem button key="punjabi" onClick={() => handleMenuClick("pj")}>
                    <ListItemIcon>{i18n.language === 'pj' ? <CheckIcon /> : <ClearIcon style={{ color: 'white' }} />}</ListItemIcon>
                    <ListItemText primary={t('pj')} />
                </ListItem>

            </List>

        )
    };


    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    return (
        <div className='toolbar' >
            <Row gutter={useWindowSize() >= 1024 ? 2 : 0}  >
                <Col span={useWindowSize() >= 1024 ? 14 : i18n.language === 'ko' ? 13 : 16} offset={useWindowSize() >= 1024 ? 2 : 0} style={{ textAlign: 'left', padding: window.innerWidth < 1200 ? '8px 0px 16px 12px' : '24px 24px 0px 16px' }}>
                    <Label labelType={window.innerWidth >= 1024 ? "H5" : "H5"} caption={t('menu.title.long')} style={{ marginLeft: window.innerWidth >= 1024 ? "0px" : "16px" }} />
                    <img src={girl3} alt='girl' height={useWindowSize() < 1024 ? '40px' : '60px'} width='auto' style={{ marginLeft: '24px', marginTop: '24px' }} />
                    <img src={boy2} alt='boy' className='vert-move' height={useWindowSize() < 1024 ? '40px' : '60px'} width='auto' style={{ marginTop: '24px' }} />
                    <img src={girl1} alt='girl' height={useWindowSize() < 1024 ? '40px' : '60px'} width='auto' style={{ marginRight: '24px', marginTop: '24px' }} />
                </Col>


                <Col span={useWindowSize() >= 1024 ? 7 : i18n.language === 'ko' ? 11 : 8} style={{ textAlign: 'right', padding: useWindowSize() < 1024 ? '0px 8px 0px 0px' : '0px 24px 0px 0px' }}>
                    <Item itemName='navigator.toolbar.socialMedia' tabName='' showFav={true} />
                    <Item itemName='navigator.toolbar.contact' tabName='' showFav={true} />
                </Col>

            </Row>

            <Row style={{ borderBottom: 'thin solid darkgrey', margin: '0px', background: 'white' }} >
                <Col span={useWindowSize() >= 1024 ? 14 : 24} offset={useWindowSize() >= 1024 ? 2 : 0}>
                    <div className='navBar'>

                        <Menu onClick={handleClick} style={{ fontSize: useWindowSize() < 1024 ? '18px' : '20px', display: 'inline-block' }} selectedKeys={[props.mainMenuKey]} mode="horizontal" size="large" >
                            <Menu.Item key="home" className='navBarItem'>
                                <Icon type="home" />
                                {t('menu.home')}
                            </Menu.Item>
                            <Menu.Item key="hubs" className='navBarItem'>
                                {t('menu.hubs')}
                            </Menu.Item>
                            <Menu.Item key="support" className='navBarItem'>
                                {t('menu.support')}
                            </Menu.Item>
                            <Menu.Item key="favs" className='navBarItem'>
                                {t('menu.favourites')}
                            </Menu.Item>
                            <Menu.Item key="search" className='navBarItem'>
                                {t('menu.search')}
                            </Menu.Item>
                            <Menu.Item key="aboutus" className='navBarItem'>
                                {t('menu.aboutus')}
                            </Menu.Item>

                        </Menu>
                        <div style={{ float: 'right', marginLeft: '24px', marginRight: '24px', marginTop: '10px' }}>
                            <Dropdown overlay={languageList} trigger={['click']} >
                                <div style={{ cursor: 'pointer' }}>
                                    <LanguageIcon size="large" />
                                    <Label labelType="H6" caption={language} style={{ marginTop: '0px' }} />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </Col>

            </Row>


        </div>
    );
}

