import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin:'12px 8px 8px 8px',
  },
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  
}));

export default function SearchBar(props) {
  const classes = useStyles();
  let { onChange , searchString, placeholder, width} = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange("");
  };

  return  (
        <Paper className={classes.root} style={{width:width}}>
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
              className={classes.input}
              placeholder={placeholder}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => handleChange(event)} 
              value={searchString}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={() => handleClear()}>
            <ClearIcon />
          </IconButton>
        </Paper>
      )
   
}