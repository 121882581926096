import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import { getAntLocale } from './helpers/i18n';
import { history } from './config/store/configureStore';
import ErrorBoundry from './components/ErrorBoundry';
import createRoutes from './config/routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.scss';


export default function App(props) {
  const { locale = 'enUS' } = props;
  return (
    <ErrorBoundry>
      <ConfigProvider key={locale} locale={getAntLocale(locale)}>
        <ConnectedRouter key={locale} history={history} >
          <CssBaseline />
          {createRoutes()}
        </ConnectedRouter>
      </ConfigProvider>
    </ErrorBoundry>
  );
}