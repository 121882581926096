import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MobileLayout from '../hoc/MobileLayout/MobileLayout'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../actions/menuSelection';
import Label from '../elements/Label';
import AppleShare from '../images/AppleShare.png';
import AppleAdd from '../images/AppleAdd.png';

import AppleAddToHomeScreen from '../images/AppleAddtoHomeScreen.png';
import AndroidAddToHomeScreen from '../images/AndroidInstall.jpg';
import AndroidInstallStep3 from '../images/AndroidInstallStep3.jpg';

class Install extends Component {
    state = {
        sectionName: '',
    };

    componentDidMount() {
        const { sectionName } = this.props;
        let lang = localStorage.getItem('i18nextLng');
        lang = lang.substr(0, 2);
        if (lang) { this.setLanguage(lang); }
        else { this.setLanguage('en'); }

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });
        };

        this.setState({ sectionName: sectionName, });

    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionName } = this.props;
        if (sectionName !== prevProps.sectionName)
            this.setState({ sectionName: sectionName, });
    }



    setLanguage = (language) => {
        const { i18n } = this.props;
        i18n.changeLanguage(language);
    };

   
    render() {

        const { onReturnClick ,t} = this.props;

        const hash = this.props.history.location.hash;
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "instant" });

        };


        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }

        let menuRedirect = null;
        let newMenu = "";
        let sectionName = '';
        if (this.props.sectionName !== '' && this.props.sectionName !== undefined)
            sectionName = '#' + this.props.sectionName;
        else
            sectionName = "#top";


        if (this.props.mainMenuKey !== "install" || (this.props.mainMenuKey === "install" && this.props.sectionName !== this.state.sectionName)) {
            newMenu = "/" + this.props.mainMenuKey + sectionName;
            menuRedirect = <Redirect to={newMenu} />
        }
     
       
        let   page = <MobileLayout pageName='install' pageTitle={t('installAppMenu')} returnHome={() => onReturnClick('[home]', 'home')} >

            <div style={{ padding: '16px', textAlign: 'left' }}>
                     {isIos()  ?
                        <React.Fragment>
                            <div >
                                <Label type='Body1' caption={t('tapShare')}></Label><br />
                                <img src={AppleShare} height='80px' alt={t('tapShare')} ></img>
                                <br />
                                <Label type='Body1' caption={t('addtoHomeScreen')}></Label>
                                <br /><br />
                                <img src={AppleAddToHomeScreen} width='240px' alt={t('addtoHomeScreen')}></img>
                                 <br /><br />
                                <Label type='Body1' caption={t('clickAdd')}></Label>
                                <br /><br />
                                <img src={AppleAdd} style={{ border: "thin solid lightgrey" }} width='auto' alt={t('addtoHomeScreen')}></img>

                            </div>
                          
                           
                        </React.Fragment>
                        :
                        <div >
                            <Label type='Body1' caption={t('tapMenu')}></Label><br /><br />
                            <Label type='Body1' caption={t('addtoHomeScreenAndroid')}></Label>
                            <div style={{textAlign:'center', width:'100%', padding:'16px 40px 16px 40px'}}>
                                  <img src={AndroidAddToHomeScreen} style ={{border: "thin solid lightgrey"}} width='100%' alt={t('addtoHomeScreen')}></img>
                            </div>
                            <Label type='Body1' caption={t('clickAdd')}></Label>
                            <br /><br />
                            <img src={AndroidInstallStep3} width='auto' alt={t('addtoHomeScreen')}></img>
                        </div>}
                </div>
            </MobileLayout>;


        return (
            <React.Fragment>
                {menuRedirect}
                {page}
               
            </React.Fragment>
        )

    }
}
const mapStateToProps = state => {
    return {
        mainMenuSelection: state.menuSelection.mainMenuSelection,
        mainMenuKey: state.menuSelection.mainMenuKey,
        mainMenuName: state.menuSelection.mainMenuName,
        mobileMenuIndex: state.menuSelection.mobileMenuIndex,
        sectionName: state.menuSelection.sectionName
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMenuClick: (mainMenuSelection, mainMenuKey, sectionName) => dispatch(actions.setMainMenu(mainMenuSelection, mainMenuKey, sectionName)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Install));
