import React, { Component } from 'react';

class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ margin: '1em' }}>
                    <h1>Sorry, something went wrong.</h1>
                    <p>
                        Please <a href="mailto:info@betterworldsoftware.ca">report</a> this error to us. You may
            also <a href="/">refresh</a> the page or try again later.
          </p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;