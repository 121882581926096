import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './config/store/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

const colorToolbar = 'rgba(0,80,124, 1)';
const initialState = {};
const store = configureStore(initialState);

const theme = createTheme({
  typography: {
    fontFamily: 'Freude, Yorkstone Flat, Roboto',
  },
  palette: {
    primary: { main: colorToolbar,light: purple, dark: 'white'},
    secondary: { main: colorToolbar, light: 'red',  dark: 'green' },
  },
  // shadows: ["none"],
});


const app = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n} >
        <App />
      </I18nextProvider>
    </Provider>
  </ThemeProvider>

);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.register();